import { Board, Clip, ViewTypeName } from '@air/api/types';
import { Upload } from '@air/redux-uploader';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GALLERY_ASSETS, GALLERY_BOARDS, GALLERY_FILES } from '~/constants/react-query-keys';
import { useRegisterClipEvents } from '~/hooks/useRegisterClipEvents';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { uploadsForCurrentBoardSelector } from '~/store/centralizedBoard/selectors';
import {
  currentSortFieldDirectionSelector,
  currentSortFieldNameSelector,
  currentViewTypeNameSelector,
} from '~/store/configViews/selectors';
import { useGalleryViewItems } from '~/swr-hooks/gallery/galleryView/useGalleryViewItems';
import { usePrivateBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePrivateBoardsFetcher';
import {
  galleryViewDataToUpdateMediaPlayer,
  getAssetFromAssetsData,
  privateClipsFetcher,
  privateFilesFetcher,
} from '~/swr-hooks/gallery/galleryView/utils';
import { defaultAssetsData, defaultBoardsData, UseGalleryViewItemsReturn } from '~/swr-hooks/gallery/types';
import { useCanShowAssets, useCanShowBoards, useCanShowFiles } from '~/swr-hooks/gallery/useCanShow';
import { usePrivateMediaPlayerDataUpdate } from '~/swr-hooks/mediaPlayer/usePrivateMediaPlayerData';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';

export interface UsePrivateGalleryViewItemsProps {
  showBoards: boolean;
  boardsExpanded: boolean;
  assetsExpanded: boolean;
}

export interface UsePrivateGalleryViewItemsReturn extends UseGalleryViewItemsReturn<Board, Clip> {
  data: UseGalleryViewItemsReturn<Board, Clip>['data'] & { uploads: Upload[] };
}

export function usePrivateGalleryViewItems({
  showBoards,
  boardsExpanded,
  assetsExpanded,
}: UsePrivateGalleryViewItemsProps): UsePrivateGalleryViewItemsReturn {
  const { privateBoardsFetcher } = usePrivateBoardsFetcher();
  const { updatePrivateMediaPlayerData } = usePrivateMediaPlayerDataUpdate();
  const sortFieldName = useSelector(currentSortFieldNameSelector);
  const sortDirection = useSelector(currentSortFieldDirectionSelector);
  const clipsFetchParams = useClipSearchParams();
  const boardsFetchParams = useBoardSearchParams();
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const { pathname } = useRouter();
  const basePathName = pathname.split('/')[1];

  const uploads = useSelector(uploadsForCurrentBoardSelector);
  const { canShowAssets } = useCanShowAssets();
  const { canShowFiles } = useCanShowFiles();
  const { currentWorkspace } = useCurrentWorkspace();

  /**
   * The reason there are 2 "show boards" checks is because _canShowBoards is used to determine if we should
   * show boards from user-applied logic (filters, flattened view, etc.). showBoards comes from higher up
   * (at the page level - like in AllContent) and is a boolean we (the product) use to determine if we should
   * show boards.
   */
  const { canShowBoards: _canShowBoards } = useCanShowBoards();
  const canShowBoards = showBoards && _canShowBoards;

  const dataCacheParams = useMemo(() => {
    return {
      ...clipsFetchParams,
      ...boardsFetchParams,
      canShowBoards,
      canShowFiles,
      canShowAssets,
      sortFieldName,
      sortDirection,
      basePathName,
      currentWorkspaceId: currentWorkspace?.id,
    };
  }, [
    basePathName,
    clipsFetchParams,
    boardsFetchParams,
    canShowBoards,
    canShowFiles,
    canShowAssets,
    sortFieldName,
    sortDirection,
    currentWorkspace?.id,
  ]);

  const {
    isLoading: areBoardsLoading,
    data: boardsData,
    fetchNextPage: loadNextBoardPage,
    isFetchingNextPage: areBoardsValidating,
    hasNextPage: hasMoreBoards,
  } = useInfiniteQuery({
    queryKey: [GALLERY_BOARDS, dataCacheParams],
    queryFn: canShowBoards
      ? ({ pageParam }) =>
          privateBoardsFetcher({
            params: boardsFetchParams,
            sortFieldName,
            sortDirection,
            cursor: pageParam || null,
          })
      : () => defaultBoardsData,
    enabled: ViewTypeName.gallery === currentViewTypeName && !!currentWorkspace?.id,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  const assetsParams = { params: clipsFetchParams, sortDirection, sortFieldName };

  const areAllBoardsLoaded =
    !!boardsData && !!currentViewTypeName && (!canShowBoards || !hasMoreBoards || !boardsExpanded);

  const {
    data: clipsData,
    fetchNextPage: loadNextClipsPage,
    isFetchingNextPage: areClipsValidating,
    isLoading: areClipsLoading,
    hasNextPage: hasMoreClips,
  } = useInfiniteQuery({
    queryKey: [GALLERY_ASSETS, dataCacheParams],
    queryFn: canShowAssets
      ? ({ pageParam }) =>
          privateClipsFetcher({ ...assetsParams, cursor: pageParam || null, workspaceId: currentWorkspace!.id })
      : () => defaultAssetsData,
    enabled: areAllBoardsLoaded && !!currentWorkspace?.id,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  const areAllClipsLoaded = !!clipsData && (!canShowAssets || !hasMoreClips || !assetsExpanded);

  useRegisterClipEvents({
    clips: getAssetFromAssetsData(clipsData?.pages),
    workspaceId: currentWorkspace?.id,
  });

  const {
    data: filesData,
    fetchNextPage: loadNextFilesPage,
    isFetchingNextPage: areFilesValidating,
    isLoading: areFilesLoading,
    hasNextPage: hasMoreFiles,
  } = useInfiniteQuery({
    queryKey: [GALLERY_FILES, dataCacheParams],
    queryFn: canShowFiles
      ? ({ pageParam }) =>
          privateFilesFetcher({ ...assetsParams, cursor: pageParam || null, workspaceId: currentWorkspace!.id })
      : () => defaultAssetsData,
    enabled: areAllBoardsLoaded && areAllClipsLoaded && !!currentWorkspace?.id,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  const itemsData = useGalleryViewItems({
    boards: {
      isValidating: areBoardsLoading || areBoardsValidating || !currentViewTypeName,
      data: boardsData?.pages,
      loadNextPage: loadNextBoardPage,
      isVisible: canShowBoards,
      hasMore: !!hasMoreBoards,
    },
    clips: {
      isValidating: areClipsLoading || areClipsValidating || !currentViewTypeName,
      data: clipsData?.pages,
      loadNextPage: loadNextClipsPage,
      isVisible: canShowAssets,
      hasMore: !!hasMoreClips,
    },
    files: {
      isValidating: areFilesValidating || areFilesLoading || !currentViewTypeName,
      data: filesData?.pages || [],
      loadNextPage: loadNextFilesPage,
      isVisible: canShowFiles,
      hasMore: !!hasMoreFiles,
    },
  });

  useEffect(() => {
    updatePrivateMediaPlayerData(galleryViewDataToUpdateMediaPlayer(itemsData));
  }, [itemsData, updatePrivateMediaPlayerData]);

  return useMemo(
    () => ({
      ...itemsData,
      data: {
        ...itemsData.data,
        uploads,
      },
      isEmpty: itemsData.isEmpty && !uploads.length,
    }),
    [itemsData, uploads],
  );
}
