import { memo, useCallback } from 'react';

import { PrivateAudioSampleRatesSelect } from '~/components/Filters/AudioSampleRateFilter/components/PrivateAudioSampleRatesSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';

interface PrivateAudioSampleRateFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateAudioSampleRateFilterCard = memo(
  ({ boardId, libraryId, ...props }: PrivateAudioSampleRateFilterCardProps) => {
    const renderAudioSampleRatesSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
      (props) => <PrivateAudioSampleRatesSelect libraryId={libraryId} boardId={boardId} {...props} />,
      [boardId, libraryId],
    );

    return (
      <StringArrayFilterCard
        filter="audioSampleRates"
        filterCardType="audioSampleRate"
        StringSelect={renderAudioSampleRatesSelect}
        {...props}
      />
    );
  },
);

PrivateAudioSampleRateFilterCard.displayName = 'PrivateAudioSampleRateFilterCard';
