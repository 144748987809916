import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import DragType, {
  GetBoardDragItem,
  isBoardDragType,
  RearrangableItem,
  RearrangableItemType,
} from '~/components/Shared/Drag/dragTypes';
import { useRearrangeAssets, useRearrangeBoards } from '~/swr-hooks/gallery/useGalleryRearrange';

import { useURLBoardIdSelector } from './useURLBoardIdSelector';

interface UseRearrangableGalleryItemsArgs {
  assets: Clip[];
  boards: Board[];
  files: Clip[];
}

interface UseRearrangeableGalleryItemsReturn {
  /** This method is used to rearrange items, it is called when item is dropped */
  rearrangeItems: (draggedItem: RearrangableItemType, adjacentItem: RearrangableItem) => void;
}

/**
 * This hook is used to manage rearrangeable items in gallery view
 * @param assets
 * @param boards
 * @param files
 */
export function useRearrangeableGalleryItems({
  assets,
  boards,
  files,
}: UseRearrangableGalleryItemsArgs): UseRearrangeableGalleryItemsReturn {
  const currentBoardId = useURLBoardIdSelector();

  const { rearrangeBoards } = useRearrangeBoards();
  const { rearrangeAssets } = useRearrangeAssets();

  const rearrangeItems = useCallback(
    async (draggedItem: RearrangableItemType, adjacentItem: RearrangableItem) => {
      const adjacentItemType = adjacentItem.type;
      const draggedItemType = draggedItem.type;
      if (adjacentItemType === GalleryItemType.board && isBoardDragType(draggedItemType)) {
        rearrangeBoards({
          boards,
          draggedItem: draggedItem as GetBoardDragItem,
          adjacentItem,
          parentBoardId: currentBoardId ?? null,
        });
      } else if (draggedItemType === DragType.asset && adjacentItemType === GalleryItemType.asset) {
        rearrangeAssets(assets, draggedItem, adjacentItem, GalleryItemType.asset);
      } else if (draggedItemType === DragType.file && adjacentItemType === GalleryItemType.file) {
        rearrangeAssets(files, draggedItem, adjacentItem, GalleryItemType.file);
      }
    },
    [currentBoardId, boards, assets, files, rearrangeBoards, rearrangeAssets],
  );

  return {
    rearrangeItems,
  };
}
