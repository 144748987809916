import { EntityLookupOptions, Search } from '@air/api';
import { WorkspaceInfo } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const PRIVATE_FILTERS_ENTITIES = 'PRIVATE_FILTERS_ENTITIES';

export const getPrivateFiltersEntitiesKey = (options: EntityLookupOptions, workspaceId?: WorkspaceInfo['id']) => [
  PRIVATE_FILTERS_ENTITIES,
  { workspaceId, ...options },
];

export const useGetPrivateFiltersEntities = (options: EntityLookupOptions) => {
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery({
    queryKey: getPrivateFiltersEntitiesKey(options, currentWorkspace?.id),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Search.getEntityLookups({ workspaceId, options });
    },
    enabled: !!currentWorkspace?.id,
  });
};
