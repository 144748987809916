import { Board } from '@air/api/types';
import { memo, useCallback } from 'react';

import { PrivateCopyrightSelect } from '~/components/Filters/CopyrightFilter/components/PrivateCopyrightSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PrivateCopyrightFilterCardProps extends BaseFilterCardProps {
  boardId: Board['id'] | undefined;
  libraryId: string | undefined;
}

export const PrivateCopyrightFilterCard = memo(({ boardId, libraryId, ...props }: PrivateCopyrightFilterCardProps) => {
  const renderCopyrightSelect = useCallback(
    (props: BaseStringSelectProps) => <PrivateCopyrightSelect {...props} boardId={boardId} libraryId={libraryId} />,
    [boardId, libraryId],
  );

  return (
    <StringArrayFilterCard
      filter="copyrights"
      filterCardType="copyright"
      StringSelect={renderCopyrightSelect}
      {...props}
    />
  );
});

PrivateCopyrightFilterCard.displayName = 'PrivateCopyrightFilterCard';
