import { Search } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const getPrivateSourcesListKey = ({
  workspaceId,
  boardId,
  libraryId,
}: {
  workspaceId: string | undefined;
  boardId: Board['id'] | undefined;
  libraryId: string | undefined;
}) => ['SOURCES', { workspaceId, boardId, libraryId }];

export const usePrivateSourcesList = ({
  boardId,
  libraryId,
}: {
  boardId: Board['id'] | undefined;
  libraryId: string | undefined;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getPrivateSourcesListKey({ workspaceId: currentWorkspace?.id, boardId, libraryId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(currentWorkspace?.id, 'Workspace ID is required');

      // no need for search here - the number of sources is limited, shouldn't be more than ~20
      const data = await Search.getAssetVersionFilterLookups({
        limit: 50,
        boardId,
        workspaceId: currentWorkspace.id,
        field: 'source',
        libraryId,
      });

      return data.lookups.map((lookup) => lookup.value.toString());
    },
    enabled: !!currentWorkspace?.id,
  });
};
