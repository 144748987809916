import { Board } from '@air/api/types';
import { useEffect, useRef } from 'react';

import { useAppliedCustomFieldsFilters } from '~/hooks/filters/useAppliedCustomFieldsFilters';
import { useAppliedTagsFilters } from '~/hooks/filters/useAppliedTagsFilters';
import { useSetFiltersFromContextToRedux } from '~/hooks/filters/useSetFiltersFromContextToRedux';
import { useGetPrivateFilterOptions } from '~/swr-hooks/filters/useGetPrivateFilterOptions';
import { useGetPrivateFiltersEntities } from '~/swr-hooks/filters/useGetPrivateFiltersEntities';

export const useSetPrivateFiltersFromContextToRedux = (boardId?: Board['id']) => {
  const firstChecked = useRef(false);

  const { appliedTagsFilters } = useAppliedTagsFilters();
  const { appliedCustomFieldsFilters } = useAppliedCustomFieldsFilters();

  const { data: options } = useGetPrivateFilterOptions(boardId);
  const { data: entities } = useGetPrivateFiltersEntities({
    tagLabels: appliedTagsFilters,
    customFields: appliedCustomFieldsFilters,
  });

  const { setFiltersFromContextToRedux } = useSetFiltersFromContextToRedux({
    options,
    entities,
  });

  useEffect(() => {
    if (!!options && !!entities && !firstChecked.current) {
      firstChecked.current = true;
      setFiltersFromContextToRedux();
    }
  }, [options, entities, setFiltersFromContextToRedux]);

  return {
    setFiltersFromContextToRedux,
  };
};
