import { memo, useCallback } from 'react';

import { PrivateCountriesSelect } from '~/components/Filters/CountryFilter/components/PrivateCountriesSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PrivateCountriesFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCountryFilterCard = memo(({ boardId, libraryId, ...props }: PrivateCountriesFilterCardProps) => {
  const renderCountriesSelect = useCallback(
    (props: BaseStringSelectProps) => <PrivateCountriesSelect boardId={boardId} libraryId={libraryId} {...props} />,
    [boardId, libraryId],
  );

  return (
    <StringArrayFilterCard
      filter="countries"
      filterCardType="country"
      StringSelect={renderCountriesSelect}
      {...props}
    />
  );
});

PrivateCountryFilterCard.displayName = 'PrivateCountryFilterCard';
