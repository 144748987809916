import { AirActionTrackingLocation } from '@air/analytics';
import { SearchSavedFiltersResponse, SuggestionsResponse } from '@air/api/types';
import Router from 'next/router';
import { memo, PropsWithChildren, Ref, useCallback } from 'react';

import { AssetsSearchBar } from '~/components/AssetsSearchBar/AssetsSearchBar';
import { SearchBarHandle, SearchBarProps } from '~/components/SearchBar/SearchBar';
import { BoardSuggestionType } from '~/components/SearchBar/types';
import { createSavedFilterRoute } from '~/constants/routes';
import { useSearchParams } from '~/hooks/search/useSearchParams';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';

export interface BasePrivateSearchProps {
  placeholder?: string;
  onCloseClick: () => void;
  trackLocation: AirActionTrackingLocation | string;
}

export interface PrivateSearchProps extends BasePrivateSearchProps {
  onFocusChange?: SearchBarProps['onFocusChange'];
  onBackspacePress?: SearchBarProps['onBackspacePress'];
  suggestions?: SuggestionsResponse;
  onSearchChange: SearchBarProps['onSearchTermChange'];
  onApplyTextSearch?: SearchBarProps['onApplyTextSearch'];
  onSearchClear?: SearchBarProps['onSearchClear'];
  searchBarRef?: Ref<SearchBarHandle>;
}

export const PrivateSearch = memo(
  ({
    onCloseClick,
    placeholder,
    trackLocation,
    onFocusChange,
    onBackspacePress,
    children,
    suggestions,
    onSearchChange,
    searchBarRef,
    onApplyTextSearch,
    onSearchClear,
  }: PropsWithChildren<PrivateSearchProps>) => {
    const { search, setSearch, clearSearch } = useSearchParams();

    const { goToBoardPage } = useGoToBoardPage();

    const _onApplyTextSearch = useCallback(
      (newTerm: string) => {
        onApplyTextSearch?.(newTerm);
        setSearch(newTerm);
      },
      [onApplyTextSearch, setSearch],
    );

    const onClose = useCallback(() => {
      clearSearch();
      onCloseClick();
    }, [clearSearch, onCloseClick]);

    const onBoardClick = useCallback(
      (board: BoardSuggestionType) => goToBoardPage({ board, trackLocation: trackLocation || 'search' }),
      [goToBoardPage, trackLocation],
    );

    const onSavedFilterClick = useCallback((savedFilter: SearchSavedFiltersResponse) => {
      return Router.push(createSavedFilterRoute(savedFilter.id));
    }, []);

    return (
      <AssetsSearchBar
        ref={searchBarRef}
        onBackspacePress={onBackspacePress}
        trackLocation={trackLocation}
        searchTerm={search}
        onCloseClick={onClose}
        onSearchClear={onSearchClear}
        onApplyTextSearch={_onApplyTextSearch}
        placeholder={placeholder || 'Search'}
        onFocusChange={onFocusChange}
        onSearchTermChange={onSearchChange}
        onSavedFilterClick={onSavedFilterClick}
        savedFilterSuggestions={suggestions?.savedFilters}
        textSuggestions={suggestions?.suggestions}
        boardSuggestions={suggestions?.boards}
        onBoardClick={onBoardClick}
      >
        {children}
      </AssetsSearchBar>
    );
  },
);

PrivateSearch.displayName = 'PrivateSearch';
