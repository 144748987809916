import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';
import { PrivateVideoAspectRatiosSelect } from '~/components/Filters/VideoAspectRatioFilter/components/PrivateVideoAspectRatiosSelect';

interface PrivateVideoAspectRatioFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateVideoAspectRatioFilterCard = memo(
  ({ boardId, libraryId, ...props }: PrivateVideoAspectRatioFilterCardProps) => {
    const renderVideoAspectRatiosSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
      (props) => <PrivateVideoAspectRatiosSelect libraryId={libraryId} boardId={boardId} {...props} />,
      [boardId, libraryId],
    );

    return (
      <StringArrayFilterCard
        filter="videoAspectRatios"
        filterCardType="videoAspectRatio"
        StringSelect={renderVideoAspectRatiosSelect}
        {...props}
      />
    );
  },
);

PrivateVideoAspectRatioFilterCard.displayName = 'PrivateVideoAspectRatioFilterCard';
