import { WorkspaceMember } from '@air/api/types';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import {
  mapOptionToUploaderFilter,
  mapUploaderFilterToOption,
  UploaderFilter,
} from '~/components/Filters/UploaderFilter/UploaderFilter';
import {
  clearUploadersFilterValuesAction,
  removeFilterAction,
  setUploadersFilterValuesAction,
} from '~/store/filters/actions';
import { selectedUploadersFilterSelector } from '~/store/filters/selectors';
import { FiltersState, SelectedFilter } from '~/store/filters/types';

export interface Uploader {
  id: WorkspaceMember['id'];
  firstName: WorkspaceMember['firstName'];
  lastName: WorkspaceMember['lastName'];
  avatar: WorkspaceMember['avatar'];
  count?: number;
}

export interface UploaderFilterCardProps extends BaseFilterCardProps {
  uploaders: FiltersState['uploaders'];
}

export const UploaderFilterCard = memo(({ uploaders, ...props }: UploaderFilterCardProps) => {
  const dispatch = useDispatch();
  const selectedUploaders = useSelector(selectedUploadersFilterSelector);

  const clearUploaders = useCallback(() => {
    dispatch(clearUploadersFilterValuesAction());
  }, [dispatch]);

  const uploadersOptions = useMemo(() => uploaders.map((u) => mapUploaderFilterToOption(u)), [uploaders]);

  const selectedOptions = useMemo(
    () => selectedUploaders.map((u) => mapUploaderFilterToOption(u)),
    [selectedUploaders],
  );

  const onUploaderSelectionChange = useCallback(
    (uploaders: Uploader[]) => {
      dispatch(setUploadersFilterValuesAction({ uploaders: uploaders.map((u) => mapOptionToUploaderFilter(u)) }));
    },
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearUploaders();
  };

  return (
    <FilterCard currentFilter="uploader" onClearClick={clearUploaders} onRemoveFilter={onRemoveFilter} {...props}>
      <UploaderFilter
        onUploaderSelectionChange={onUploaderSelectionChange}
        uploadersOptions={uploadersOptions}
        selectedOptions={selectedOptions}
      />
    </FilterCard>
  );
});

UploaderFilterCard.displayName = 'UploaderFilterCard';
