import { Search } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const getImportedKeywordsListKey = ({
  workspaceId,
  boardId,
  search,
  libraryId,
}: {
  workspaceId: string | undefined;
  boardId: Board['id'] | undefined;
  search: string;
  libraryId: string | undefined;
}) => ['IMPORTED_KEYWORDS', { workspaceId, boardId, search, libraryId }];

export const useImportedKeywordsList = ({
  search,
  boardId,
  libraryId,
}: {
  search: string;
  boardId: Board['id'] | undefined;
  libraryId: string | undefined;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getImportedKeywordsListKey({ workspaceId: currentWorkspace?.id, boardId, search, libraryId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(currentWorkspace?.id, 'Workspace ID is required');

      const data = await Search.getAssetVersionFilterLookups({
        limit: 20,
        workspaceId: currentWorkspace.id,
        field: 'keyword',
        search,
        boardId,
        libraryId,
      });

      return data.lookups.map(({ value }) => value.toString());
    },
    enabled: !!currentWorkspace?.id,
  });
};
