import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateCameraMakesList } from '~/swr-hooks/filters/usePrivateCameraMakesList';

interface PrivateCameraMakeSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCameraMakeSelect = memo(({ boardId, libraryId, ...props }: PrivateCameraMakeSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePrivateCameraMakesList({
    search: debouncedSearchValue,
    boardId,
    libraryId,
  });

  return (
    <StringSelect
      data-testid="CAMERA_MAKE_SELECT"
      placeholder="Search for make"
      closeMenuOnSelect
      isSingleSelect={true}
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PrivateCameraMakeSelect.displayName = 'PrivateCameraMakeSelect';
