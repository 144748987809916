import { memo } from 'react';

import { MobilePageTitle } from '~/components/MobilePageTitle';

export interface MobileHeaderProps {
  title?: string;
  isCentered?: boolean;
}

export const MobilePageHeader = memo(({ title, isCentered = false }: MobileHeaderProps) => {
  return (
    <div className="flex grow items-center">
      {/** This is the hamburger menu on mobile that opens the sidebar */}
      {!!title && <MobilePageTitle title={title} isCentered={isCentered} />}
    </div>
  );
});

MobilePageHeader.displayName = 'MobilePageHeader';
