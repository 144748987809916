import { useBreakpointsContext } from '@air/provider-media-query';
import { ComponentType, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PrivateBoardNullState } from '~/components/PrivateBoard/PrivateBoardNullState';
import { PrivateCustomFieldTableHeader } from '~/components/TableView/CustomFieldTableHeader/PrivateCustomFieldTableHeader';
import { TableViewProps } from '~/components/TableView/index';
import { SelectableTableView, SelectableTableViewProps } from '~/components/TableView/SelectableTableView';
import { TableHeaders } from '~/components/TableView/TableHeaders';
import { getMinTableWidth } from '~/components/TableView/types';
import { TablePageContainer } from '~/components/TableView/ui';
import { UsePrivateGetTableRowsParams, usePrivateTableRows } from '~/components/TableView/usePrivateTableRows';
import { useTableSelectableItems } from '~/components/TableView/useTableSelectableItems';
import { BOARD_PAGE_TABLE_VIEW } from '~/constants/testIDs';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { usePrivateWorkspaceHorizontalPadding } from '~/hooks/usePrivateWorkspaceHorizontalPadding';
import { useTableHeaderPosition } from '~/hooks/useTableHeaderPosition';
import { activeTableColumnsSelector } from '~/store/configViews/selectors';
import { usePrivateTableViewItems } from '~/swr-hooks/gallery/tableView/usePrivateTableViewItems';
import { itemIsAsset, itemIsBoard, itemIsFile } from '~/swr-hooks/gallery/types';

export interface PrivateTableViewProps extends Pick<UsePrivateGetTableRowsParams, 'renderAssetRow' | 'renderBoardRow'> {
  EmptyState?: ComponentType;
  scrollElementRef: SelectableTableViewProps['scrollElementRef'];
  showBoards?: boolean;
}

export const PrivateTableView = memo(
  ({
    EmptyState = PrivateBoardNullState,
    scrollElementRef,
    renderAssetRow,
    renderBoardRow,
    showBoards = true,
  }: PrivateTableViewProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();
    const { responsiveHorizontalPadding } = usePrivateWorkspaceHorizontalPadding();
    const { tableHeaderTopPos } = useTableHeaderPosition();

    const isBoardSelectable = useCallback(() => true, []);
    const isAssetSelectable = useCallback(() => true, []);

    const { data, uploads, hasMore, isEmpty, isLoading, isLoadingMore, loadNextPage } = usePrivateTableViewItems({
      showBoards,
    });

    const assets = useMemo(() => data?.filter(itemIsAsset).map((item) => item.item), [data]);
    const files = useMemo(() => data?.filter(itemIsFile).map((item) => item.item), [data]);
    const boards = useMemo(() => data?.filter(itemIsBoard).map((item) => item.item), [data]);

    useFetchObjectsPermissions({
      objects: {
        boardIds: boards.map(({ id }) => id),
        clipIds: [...assets, ...files].map(({ id }) => id),
        /**
         * We need to fetch permissions for assets because we use their permissions for certain endpoints
         * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1695838522133639
         */
        assetIds: [...assets, ...files].map(({ assetId }) => assetId),
      },
    });

    const rows = usePrivateTableRows({
      data,
      hasMore,
      isLoading,
      isLoadingMore,
      uploads,
      loadMore: loadNextPage,
      renderAssetRow,
      renderBoardRow,
      isAssetSelectable,
      isBoardSelectable,
    });

    const selectableItems = useMemo(
      () =>
        (data || []).filter((item) => {
          if (itemIsBoard(item)) {
            return isBoardSelectable();
          } else {
            return isAssetSelectable();
          }
        }),
      [data, isAssetSelectable, isBoardSelectable],
    );

    const hasData = !!data?.length;

    const renderTableHeaders: Required<TableViewProps>['renderHeaders'] = useCallback(
      (ref) =>
        hasData &&
        isAboveMediumScreen && (
          <TableHeaders
            ref={ref}
            CustomFieldTableHeaderComponent={PrivateCustomFieldTableHeader}
            horizontalPadding={responsiveHorizontalPadding}
            topPos={tableHeaderTopPos}
          />
        ),
      [hasData, isAboveMediumScreen, responsiveHorizontalPadding, tableHeaderTopPos],
    );

    useTableSelectableItems(selectableItems ?? []);

    const activeTableColumns = useSelector(activeTableColumnsSelector);
    const minTableWidth = getMinTableWidth(activeTableColumns);

    return (
      <TablePageContainer data-testid={BOARD_PAGE_TABLE_VIEW}>
        {isEmpty ? (
          <EmptyState />
        ) : (
          <SelectableTableView
            canScrollHorizontally={isAboveMediumScreen && hasData}
            minTableWidth={minTableWidth}
            horizontalPadding={responsiveHorizontalPadding}
            renderHeaders={renderTableHeaders}
            rows={rows}
            scrollElementRef={scrollElementRef}
          />
        )}
      </TablePageContainer>
    );
  },
);

PrivateTableView.displayName = 'PrivateTableView';
