import { EditableTextFormValues } from '@air/zephyr-editable-text';
import { memo, useCallback } from 'react';

import { ItemProps } from '~/components/Gallery/types';
import { AssetMetaData, AssetMetaDataProps } from '~/components/TableView/NameCell/AssetNameCell/AssetMetaData';
import { EditableTableTitle } from '~/components/TableView/NameCell/NameCellContent/EditableTableTitle';
import { TableAssetTitle } from '~/components/TableView/NameCell/NameCellContent/TableAssetTitle';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { useUpdateAssetTitle } from '~/swr-hooks/clips/useUpdateAssetTitle';
import { canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export interface PrivateAssetNameCellTitleProps
  extends Pick<AssetMetaDataProps, 'assetVersionCount' | 'openCommentCount'> {
  isRenaming: boolean;
  setIsRenaming: (isRenaming: boolean) => void;
  onClick: ItemProps['onItemClick'];
  onCmdClick: ItemProps['onItemCmdClick'];
  onCommentsClick?: () => void;
  onVersionsClick?: () => void;
  title: string;
  id: string;
  assetId: string;
}

export const PrivateAssetNameCellTitle = memo(
  ({
    isRenaming,
    onClick,
    onCmdClick,
    onCommentsClick,
    onVersionsClick,
    title,
    id,
    assetId,
    assetVersionCount,
    openCommentCount,
    setIsRenaming,
  }: PrivateAssetNameCellTitleProps) => {
    const { updateAssetTitle } = useUpdateAssetTitle();
    const { clipPermissions } = useClipPermissions({
      clipId: id,
    });
    const canViewDiscussions = canViewAssetDiscussions(clipPermissions);

    const onRenameComplete = useCallback(
      (values: EditableTextFormValues) => {
        const title = values['editable-text-value'].trim();
        updateAssetTitle(id, title, assetId);
        setIsRenaming(false);
      },
      [updateAssetTitle, id, assetId, setIsRenaming],
    );

    return (
      <div
        className="relative ml-3 flex flex-col items-start text-left"
        style={{
          zIndex: isRenaming ? 9999 : undefined,
        }}
        data-disableselect="true"
        onDoubleClick={(e) => {
          if (isRenaming) e.stopPropagation();
        }}
        onMouseDown={(e) => {
          if (isRenaming) e.stopPropagation();
        }}
        draggable={isRenaming ? true : undefined}
        onDragStart={(e) => {
          if (isRenaming) e.preventDefault();
        }}
      >
        {isRenaming ? (
          <EditableTableTitle title={title} onRenameComplete={onRenameComplete} />
        ) : (
          <TableAssetTitle title={title} onClick={onClick} onCmdClick={onCmdClick} />
        )}
        {!isRenaming && (
          <AssetMetaData
            assetVersionCount={assetVersionCount}
            onCommentsClick={onCommentsClick}
            onVersionsClick={onVersionsClick}
            openCommentCount={openCommentCount}
            shouldShowDiscussions={canViewDiscussions}
            shouldShowVersions={true}
          />
        )}
      </div>
    );
  },
);

PrivateAssetNameCellTitle.displayName = 'PrivateAssetNameCellTitle';
