import { memo, useCallback } from 'react';

import { PrivateCreatorSelect } from '~/components/Filters/CreatorFilter/components/PrivateCreatorSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PrivateCreatorFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCreatorFilterCard = memo(({ boardId, libraryId, ...props }: PrivateCreatorFilterCardProps) => {
  const renderStringSelect = useCallback(
    (props: BaseStringSelectProps) => <PrivateCreatorSelect boardId={boardId} libraryId={libraryId} {...props} />,
    [boardId, libraryId],
  );

  return (
    <StringArrayFilterCard filterCardType="creator" filter="creators" StringSelect={renderStringSelect} {...props} />
  );
});

PrivateCreatorFilterCard.displayName = 'PrivateCreatorFilterCard';
