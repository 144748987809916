import { CSSProperties } from 'react';

import DragType from '~/components/Shared/Drag/dragTypes';
import { dropHighlightOffset } from '~/components/Shared/DropHighlight';
import { useIsDragging } from '~/hooks/useIsDragging';

interface DraggingOverlayProps {
  itemId: string;
  type: DragType;
  style?: CSSProperties;
}

export const DraggingOverlay = ({ itemId, type, style }: DraggingOverlayProps) => {
  const isDragging = useIsDragging(itemId, type);

  return isDragging ? (
    <div
      className="pointer-events-none absolute bg-white opacity-80"
      style={{
        top: dropHighlightOffset - 2,
        left: dropHighlightOffset - 2,
        right: dropHighlightOffset - 2,
        bottom: dropHighlightOffset - 2,
        ...style,
      }}
    />
  ) : null;
};
