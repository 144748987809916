import { AirActionTrackingLocation, useTrackClickedFileUpload } from '@air/analytics';
import { UploadContext } from '@air/component-upload-dropzone';
import { useCallback, useContext } from 'react';

import { useActiveSubscriptionCallback } from '~/swr-hooks/subscriptions/useActiveSubscriptionCallback';

export const useUploadFilesFromComputer = ({ trackLocation }: { trackLocation: AirActionTrackingLocation }) => {
  const { openUploader } = useContext(UploadContext);
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { trackClickedFileUpload } = useTrackClickedFileUpload();

  const uploadFilesFromComputer = useCallback(
    () =>
      activeSubscriptionCallback(() => {
        openUploader();
        trackClickedFileUpload({ location: trackLocation });
      }),
    [activeSubscriptionCallback, openUploader, trackLocation, trackClickedFileUpload],
  );

  return {
    uploadFilesFromComputer,
  };
};
