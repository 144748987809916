import { Search } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const getPrivateAudioCodingsListKey = ({
  workspaceId,
  search,
  boardId,
  libraryId,
}: {
  workspaceId: string | undefined;
  search: string;
  boardId: string | undefined;
  libraryId: string | undefined;
}) => ['PRIVATE_AUDIO_CODINGS', { workspaceId, search, boardId, libraryId }];

export const usePrivateAudioCodingsList = ({
  search,
  boardId,
  libraryId,
}: {
  search: string;
  boardId: string | undefined;
  libraryId: string | undefined;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getPrivateAudioCodingsListKey({ workspaceId: currentWorkspace?.id, search, boardId, libraryId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(currentWorkspace?.id, 'Workspace ID is required');

      const data = await Search.getAssetVersionFilterLookups({
        limit: 20,
        search,
        boardId,
        field: 'audioCoding',
        workspaceId: currentWorkspace.id,
        libraryId,
      });

      return data.lookups.map(({ value }) => value.toString());
    },
    enabled: !!currentWorkspace?.id,
  });
};
