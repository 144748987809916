import { Upload } from '@air/redux-uploader';
import React, { memo, useMemo } from 'react';

import { NameCellContent, NameCellContentProps } from '~/components/TableView/NameCell/NameCellContent';
import { NoPreviewImage } from '~/components/UI/NoPreviewImage';
import { useUploadingData } from '~/components/Upload/hooks/useUploadingData';
import { getAssetPreviewType } from '~/utils/ClipUtils';

export interface UploadNameCellProps extends Pick<NameCellContentProps, 'imageSize'> {
  upload: Upload;
}

/**
 * This component displays NameCell with data for upload
 */
export const UploadNameCell = memo(
  ({
    upload,
    upload: {
      apiUploadData: { ext, type },
    },
    ...props
  }: UploadNameCellProps) => {
    const { stateText } = useUploadingData(upload);

    const image = useMemo(
      () => (
        <NoPreviewImage
          type={getAssetPreviewType({ ext, type })}
          hideText={true}
          processing={true}
          ext={ext}
          size="small"
          color="gray"
        />
      ),
      [ext, type],
    );

    return (
      <NameCellContent
        image={image}
        {...props}
        title={<div className="ml-3">{upload.apiUploadData.importedName}</div>}
        disabled={true}
      >
        <p className="truncate text-14 text-grey-10">{stateText}</p>
      </NameCellContent>
    );
  },
);

UploadNameCell.displayName = 'UploadNameCell';
