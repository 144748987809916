import { Workspaces } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getConfigViewOptionsKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { filterOutIPTCFields } from '~/swr-hooks/gallery/viewFields/filterOutIPTCFields';
import { useCanSeePrivateIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePrivateIPTCFields';

export const useGetPrivateConfigViewOptions = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const viewType = useSelector(currentViewTypeNameSelector);

  const { canSeePrivateIPTCFields } = useCanSeePrivateIPTCFields();

  return useQuery({
    queryKey: getConfigViewOptionsKey({
      workspaceId: currentWorkspace?.id,
      viewType,
      canSeeIPTCFields: canSeePrivateIPTCFields,
    }),

    queryFn: async () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      if (!viewType) {
        throw new Error('No view type');
      }

      const response = await Workspaces.listViewFields({ name: viewType, workspaceId });
      return canSeePrivateIPTCFields ? response : filterOutIPTCFields(response);
    },
    enabled: !!viewType && !!workspaceId,
  });
};
