import { memo } from 'react';
import { useSelector } from 'react-redux';

import { NoSearchResults } from '~/components/NoSearchResults/NoSearchResults';
import { SearchInAllAssetsHint } from '~/components/NoSearchResults/shared/SearchInAllAssetsHint';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';

export const PrivateBoardNoSearchResults = memo(() => {
  const currentBoardTitle = useSelector(centralizedBoardTitleSelector);

  return (
    <NoSearchResults location={currentBoardTitle}>
      <SearchInAllAssetsHint />
    </NoSearchResults>
  );
});

PrivateBoardNoSearchResults.displayName = 'PrivateBoardNoSearchResults';
