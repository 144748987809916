import { AirActionTrackingLocation, useTrackClickedImport } from '@air/analytics';
import { useCallback } from 'react';

import { ImportOptions } from '~/constants/MenuOptions';
import { useImportModal } from '~/hooks/useImportModal';
import { useActiveSubscriptionCallback } from '~/swr-hooks/subscriptions/useActiveSubscriptionCallback';

export const useImportFrom = ({ trackLocation }: { trackLocation: AirActionTrackingLocation }) => {
  const { openModal } = useImportModal();
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { trackClickedImport } = useTrackClickedImport();

  const importFrom = useCallback(
    (activePage?: ImportOptions) =>
      activeSubscriptionCallback(() => {
        openModal(activePage);
        trackClickedImport({ location: trackLocation, origin: activePage || 'import-from-drive' });
      }),
    [activeSubscriptionCallback, openModal, trackLocation, trackClickedImport],
  );

  return {
    importFrom,
  };
};
