import { memo } from 'react';

import { Uploader } from '~/components/Filters/UploaderFilter/UploaderFilterCard';
import { UploaderSelect } from '~/components/Selects/Uploader/UploaderSelect';
import { UploaderFilterType } from '~/store/filters/types';

export interface UploaderFilterProps {
  selectedOptions: Uploader[];
  uploadersOptions: Uploader[];
  onUploaderSelectionChange: (uploaders: Uploader[]) => void;
}

export const mapUploaderFilterToOption = (uploader: UploaderFilterType): Uploader => ({
  ...uploader,
  id: uploader.value,
});

export const mapOptionToUploaderFilter = (uploader: Uploader): UploaderFilterType => ({
  ...uploader,
  value: uploader.id,
});

export const UploaderFilter = memo(
  ({ onUploaderSelectionChange, uploadersOptions, selectedOptions }: UploaderFilterProps) => {
    return (
      <UploaderSelect
        hasDropdownOverlay
        variant="field-input-smol"
        uploaders={uploadersOptions}
        selectedUploaders={selectedOptions}
        onUploaderSelectionChange={onUploaderSelectionChange}
      />
    );
  },
);

UploaderFilter.displayName = 'UploaderFilter';
