import { FiltersTrackingLocation, useTrackReturnedEmptyFiltersResult } from '@air/analytics';
import { Boards, Clips, ClipsSearchParams } from '@air/api';
import { BoardsListOptions, SortDirection } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getPrivateSearchTotalKey } from '~/constants/react-query-keys';
import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useFiltersContext } from '~/providers/FiltersProvider';
import { currentSortFieldDirectionSelector, currentSortFieldNameSelector } from '~/store/configViews/selectors';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';
import { getTypesToFetchFromParams } from '~/swr-hooks/search/utils';
import { filterParamsToTrackingType } from '~/utils/filters/filtersToUrlParamsUtils';
import { insertIf } from '~/utils/insertIf';

export const usePrivateSearchTotals = ({
  trackLocation,
  showBoards,
}: {
  showBoards: boolean;
  trackLocation: FiltersTrackingLocation;
}) => {
  const clipsFetchParams = useClipSearchParams();
  const boardsFetchParams = useBoardSearchParams();
  const { typesParam } = useFilterParams();
  const { trackReturnedEmptyFiltersResult } = useTrackReturnedEmptyFiltersResult();

  clipsFetchParams.types = getTypesToFetchFromParams(typesParam);

  const sortDirection = useSelector(currentSortFieldDirectionSelector) ?? SortDirection.asc;
  const sortField = useSelector(currentSortFieldNameSelector);
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const {
    filters: { customFields, ...filters },
  } = useFiltersContext();

  const getBoardsTotal = useCallback(
    async (params: BoardsListOptions) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const response = await Boards.list({
        ...params,
        workspaceId,
        limit: 1,
      });

      return response.total;
    },
    [workspaceId],
  );

  const getClipsTotal = useCallback(async (params: ClipsSearchParams) => {
    const response = await Clips.search({
      ...params,
      limit: 1,
    });
    return response.data.total;
  }, []);

  const searchParams = useMemo(() => {
    return {
      ...clipsFetchParams,
      ...boardsFetchParams,
      ...(typesParam.length ? typesParam : undefined),
      sortField,
      sortDirection,
    };
  }, [boardsFetchParams, clipsFetchParams, sortDirection, sortField, typesParam]);

  const sort = {
    sortField: {
      name: sortField,
      direction: sortDirection || SortDirection.asc,
    },
  };

  const { data } = useQuery({
    queryKey: getPrivateSearchTotalKey(searchParams, currentWorkspace?.id),

    queryFn: async () => {
      const response = await Promise.all([
        ...insertIf(showBoards, () => [getBoardsTotal(boardsFetchParams)]),
        getClipsTotal({ ...clipsFetchParams, ...sort, workspaceId: currentWorkspace!.id }),
      ]);

      const total = response.reduce((acc, curr) => acc + curr, 0);

      if (!total) {
        trackReturnedEmptyFiltersResult({
          types: filterParamsToTrackingType({ ...filters, ...customFields }),
          location: trackLocation,
        });
      }

      return { total: response.reduce((acc, curr) => acc + curr, 0) };
    },
    enabled: !!currentWorkspace?.id,
  });

  return {
    total: data?.total,
  };
};
