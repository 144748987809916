import { memo } from 'react';

import { SourcesSelect } from '~/components/Selects/SourcesSelect';
import { BaseStringSelectProps } from '~/components/StringSelect';
import { usePrivateSourcesList } from '~/swr-hooks/filters/usePrivateSourcesList';

interface PrivateSourcesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateSourcesSelect = memo(({ boardId, libraryId, ...props }: PrivateSourcesSelectProps) => {
  const { data = [], isLoading } = usePrivateSourcesList({
    boardId,
    libraryId,
  });

  return <SourcesSelect isLoading={isLoading} sources={data} {...props} />;
});

PrivateSourcesSelect.displayName = 'PrivateSourcesSelect';
