import { Clip } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import React, { CSSProperties, memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { ItemProps } from '~/components/Gallery/types';
import { useGetPrivateAssetMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateAssetMenuOptionsCallback';
import { AssetTableRow, AssetTableRowProps } from '~/components/TableView/AssetTableRow';
import { MobileTableRowContainer } from '~/components/TableView/MobileTableRow/MobileTableRowContainer';
import { PrivateAssetNameCell } from '~/components/TableView/PrivateTableView/PrivateAssetNameCell';
import { PrivateDraggableAssetTableRow } from '~/components/TableView/PrivateTableView/PrivateDraggableAssetTableRow';
import { PrivateAssetCustomFieldTableCell } from '~/components/TableView/TableRow/CustomFieldTableCell/PrivateAssetCustomFieldTableCell';
import { DesktopTableRowContainer } from '~/components/TableView/TableRow/DesktopTableRowContainer';
import { BasicTableRowProps } from '~/components/TableView/types';
import { QueryParamNames } from '~/constants/search';
import { TABLE_ROW } from '~/constants/testIDs';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { usePrivateWorkspaceHorizontalPadding } from '~/hooks/usePrivateWorkspaceHorizontalPadding';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';

export interface PrivateAssetTableRowProps extends BasicTableRowProps<Clip> {
  /**
   * `react-virtualized` warns that `style` property should be included in the rendered cell for positioning
   * so we've separated it from the `tx` prop
   * */
  style: CSSProperties;
}

export const PrivateAssetTableRow = memo(({ data: asset, style }: PrivateAssetTableRowProps) => {
  const { getAssetMenuOptionsCallback } = useGetPrivateAssetMenuOptionsCallback();
  const { responsiveHorizontalPaddingArray } = usePrivateWorkspaceHorizontalPadding();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const itemType = getAssetGalleryItemType(asset);
  const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
  const { goToAssetPage } = useGoToAssetPage();
  const { openAssetInNewTab } = useOpenAssetInNewTab();
  const onItemClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        trackLocation: 'card-click',
      }),
    [asset, goToAssetPage],
  );
  const onItemCmdClick = useCallback(
    () =>
      openAssetInNewTab({
        asset,
        trackLocation: 'card-click',
      }),
    [asset, openAssetInNewTab],
  );

  const onCommentsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [asset, goToAssetPage],
  );

  const onVersionsClick = useCallback(() => {
    goToAssetPage({
      asset,
      query: {
        [QueryParamNames.versions]: true,
      },
      trackLocation: 'versions-click',
    });
  }, [asset, goToAssetPage]);

  const getItemMenuOptions = getAssetMenuOptionsCallback({ item: asset });

  const memoizedAssetProps: ItemProps = useMemo(
    () => ({
      isSelectable: true,
      getSelectionMenuOptions,
      getItemMenuOptions,
      onCommentsClick,
      onItemClick,
      onItemCmdClick,
      onVersionsClick,
    }),
    [getItemMenuOptions, getSelectionMenuOptions, onCommentsClick, onItemClick, onItemCmdClick, onVersionsClick],
  );

  const renderNameCell: AssetTableRowProps<Clip>['renderNameCell'] = useCallback(
    ({ isSelected, isHovering }) => (
      <PrivateAssetNameCell isHovering={isHovering} isSelected={isSelected} clip={asset} {...memoizedAssetProps} />
    ),
    [asset, memoizedAssetProps],
  );

  return isAboveMediumScreen ? (
    <DesktopTableRowContainer
      tx={{
        px: responsiveHorizontalPaddingArray,
      }}
      style={style}
      data-testid={`${TABLE_ROW}-${itemType}`}
    >
      <PrivateDraggableAssetTableRow asset={asset}>
        <AssetTableRow
          CustomFieldTableCell={PrivateAssetCustomFieldTableCell}
          assetProps={memoizedAssetProps}
          data={asset}
          renderNameCell={renderNameCell}
        />
      </PrivateDraggableAssetTableRow>
    </DesktopTableRowContainer>
  ) : (
    <MobileTableRowContainer
      tx={{
        px: responsiveHorizontalPaddingArray,
      }}
      style={style}
    >
      {renderNameCell({ isSelected: false, isHovering: false })}
    </MobileTableRowContainer>
  );
}, isEqual);

PrivateAssetTableRow.displayName = 'PrivateAssetTableRow';
