import { memo } from 'react';

import {
  BaseCustomFieldFilterCard,
  BaseCustomFieldFilterCardProps,
} from '~/components/Filters/CustomFieldFilter/BaseCustomFieldFilterCard';
import { CustomFieldFilterType } from '~/components/FiltersMenu/types';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';

export interface PrivateCustomFieldFilterCardProps extends Omit<BaseCustomFieldFilterCardProps, 'values'> {
  customField: CustomFieldFilterType;
}

export const PrivateCustomFieldFilterCard = memo(({ customField, ...props }: PrivateCustomFieldFilterCardProps) => {
  const { data } = useGetPrivateCustomFieldOptions({
    customFieldId: customField?.id,
  });

  return <BaseCustomFieldFilterCard values={data?.data ?? []} customField={customField} {...props} />;
});

PrivateCustomFieldFilterCard.displayName = 'CustomFieldFilterCard';
