import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateCountriesList } from '~/swr-hooks/filters/usePrivateCountriesList';

interface PrivateCountriesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCountriesSelect = memo(({ boardId, libraryId, ...props }: PrivateCountriesSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePrivateCountriesList({
    search: debouncedSearchValue,
    boardId,
    libraryId,
  });

  return (
    <StringSelect
      placeholder="Search for conutry"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PrivateCountriesSelect.displayName = 'PrivateCountriesSelect';
