import { useQuery, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useCallback } from 'react';

import { getPrivateMediaPlayerKey } from '~/constants/react-query-keys';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { usePrivateClipMetadata } from '~/swr-hooks/clips/usePrivateClipMetadata';
import {
  defaultMediaPlayerAssetInfo,
  MediaPlayerAssetsInfo,
  useBaseMediaPlayerData,
} from '~/swr-hooks/mediaPlayer/useMediaPlayerData';
import { defaultClipGetOptions, useGetClipWithNoWorkspaceId } from '~/swr-hooks/useGetClipWithNoWorkspaceId';

export const usePrivateMediaPlayerData = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const currentBoardId = useURLBoardIdSelector();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: getPrivateMediaPlayerKey(currentWorkspace?.id),

    queryFn: () => {
      if (!!currentWorkspace?.id) {
        return queryClient.getQueryData<MediaPlayerAssetsInfo | undefined>(
          getPrivateMediaPlayerKey(currentWorkspace?.id),
        );
      }
    },
    enabled: !!currentWorkspace?.id,
    initialData: defaultMediaPlayerAssetInfo,
  });

  const { isNearEnd, nextId, previousId } = useBaseMediaPlayerData(data);

  // assets details may be stored in redux or swr
  // we can remove 'redux....Asset' when we get rid of clipByIdSelector
  const { data: previousAsset } = useGetClipWithNoWorkspaceId({
    clipId: previousId,
    options: {
      ...defaultClipGetOptions,
      boardId: currentBoardId,
    },
  });

  const { data: nextAsset } = useGetClipWithNoWorkspaceId({
    clipId: nextId,
    options: {
      ...defaultClipGetOptions,
      boardId: currentBoardId,
    },
  });

  // Load metadata for next and previous clips to have them ready
  usePrivateClipMetadata({ clipId: previousId });
  usePrivateClipMetadata({ clipId: nextId });

  return {
    nextAsset,
    previousAsset,
    loadMore: data?.loadMore ?? noop,
    isNearEnd,
  };
};

export const usePrivateMediaPlayerDataUpdate = () => {
  const { currentWorkspace } = useCurrentWorkspace();

  const queryClient = useQueryClient();

  const updatePrivateMediaPlayerData = useCallback(
    (data: MediaPlayerAssetsInfo) => {
      if (!!currentWorkspace?.id) {
        queryClient.setQueryData(getPrivateMediaPlayerKey(currentWorkspace?.id), data);
      }
    },
    [currentWorkspace?.id, queryClient],
  );

  return {
    updatePrivateMediaPlayerData,
  };
};
