import { Board } from '@air/api/types';
import { memo, useCallback } from 'react';

import { PrivateExtensionsSelect } from '~/components/Filters/ExtensionFilter/components/PrivateExtensionsSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PrivateExtensionsFilterCardProps extends BaseFilterCardProps {
  boardId: Board['id'] | undefined;
  libraryId: string | undefined;
}

export const PrivateExtensionsFilterCard = memo(
  ({ boardId, libraryId, ...props }: PrivateExtensionsFilterCardProps) => {
    const renderExtensionsSelect = useCallback(
      (props: BaseStringSelectProps) => <PrivateExtensionsSelect {...props} boardId={boardId} libraryId={libraryId} />,
      [boardId, libraryId],
    );

    return (
      <StringArrayFilterCard
        filter="extensions"
        filterCardType="extension"
        StringSelect={renderExtensionsSelect}
        {...props}
      />
    );
  },
);

PrivateExtensionsFilterCard.displayName = 'PrivateExtensionsFilterCard';
