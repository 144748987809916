import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateCameraModelsList } from '~/swr-hooks/filters/usePrivateCameraModelsList';

interface PrivateCameraModelsSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  make: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCameraModelsSelect = memo(
  ({ boardId, make, libraryId, ...props }: PrivateCameraModelsSelectProps) => {
    const [searchValue, setSearchValue] = useState('');

    const [debouncedSearchValue] = useDebounce(searchValue, 200, {
      trailing: true,
    });

    const { data = [], isLoading } = usePrivateCameraModelsList({
      search: debouncedSearchValue,
      boardId,
      make,
      libraryId,
    });

    return (
      <StringSelect
        data-testid="CAMERA_MODELS_SELECT"
        placeholder="Search for model"
        isLoading={isLoading}
        values={data}
        onInputChange={setSearchValue}
        {...props}
      />
    );
  },
);

PrivateCameraModelsSelect.displayName = 'PrivateCameraModelsSelect';
