import { AirActionTrackingLocation } from '@air/analytics';
import { CustomFieldTypeName } from '@air/api/types';
import { memo, useCallback, useState } from 'react';

import {
  CustomFieldSelect,
  CustomFieldSelectProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldSelect';
import { chipsToCustomField, chipToCustomField } from '~/components/CustomFields/CustomFieldInput/utils';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateAssetPanelCustomFieldSelectProps
  extends Omit<CustomFieldSelectProps, 'cfOptions' | 'onSelectionChange'> {
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  assetId: string;
}

export const PrivateAssetCustomFieldSelect = memo(
  ({ field, trackingLocation, canEditCustomFields, assetId, ...rest }: PrivateAssetPanelCustomFieldSelectProps) => {
    const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;
    const { data: cfOptions } = useGetPrivateCustomFieldOptions({
      customFieldId: field.id,
      enabled: !shouldLoadOptions,
    });
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    const onSelectionChange: CustomFieldSelectProps['onSelectionChange'] = useCallback(
      async ({ addedChips, removedChips, chips }) => {
        if (!workspaceId) return;

        if (field.type.title === CustomFieldTypeName.singleSelect) {
          const value = chips.length && !!cfOptions ? chipToCustomField(chips[0], cfOptions.data) : null;
          await updateItemsCustomFieldValue({
            assetIds: [assetId],
            baseCustomField: field,
            nextValue: value,
            trackingLocation,
            workspaceId,
          });
        } else {
          const selectedFields = chips.length && !!cfOptions ? chipsToCustomField(chips, cfOptions.data) : [];
          await updateItemsCustomFieldValue({
            assetIds: [assetId],
            baseCustomField: field,
            nextValues: selectedFields,
            multiValuesToAdd: addedChips && cfOptions ? chipsToCustomField(addedChips, cfOptions.data) : undefined,
            multiValueIdsToRemove: removedChips?.map((chip) => chip.value),
            trackingLocation,
            workspaceId,
          });
        }
      },
      [assetId, cfOptions, field, trackingLocation, updateItemsCustomFieldValue, workspaceId],
    );

    return (
      <CustomFieldSelect
        {...rest}
        field={field}
        onSelectionChange={onSelectionChange}
        cfOptions={cfOptions}
        onOptionsOpen={() => setShouldLoadOptions(true)}
        isLoading={shouldLoadOptions && !cfOptions}
        disabled={!canEditCustomFields}
      />
    );
  },
);

PrivateAssetCustomFieldSelect.displayName = 'PrivateAssetPanelCustomFieldSelect';
