import { Clip } from '@air/api/types';
import { noop } from 'lodash';
import { memo } from 'react';

import { AssetGalleryCard, AssetGalleryCardProps } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCard';
import { DraggingOverlay } from '~/components/Gallery/DraggingOverlay';
import { DNDMovableToBoardItem } from '~/components/Shared/Drag/DNDMovableToBoardItem/DNDMovableToBoardItem';
import { DNDParentAsset } from '~/components/Shared/Drag/DNDParentAsset';
import DragType from '~/components/Shared/Drag/dragTypes';
import { DropHighlight } from '~/components/Shared/DropHighlight';

export interface DNDAssetGalleryCardProps extends AssetGalleryCardProps<Clip> {
  enableDrag?: boolean;
  index: number;
  onDragStart?: () => void;
}

export const DNDAssetGalleryCard = memo(
  ({ clip, enableDrag = true, index, onDragStart = noop, ...rest }: DNDAssetGalleryCardProps) => {
    return (
      <DNDMovableToBoardItem
        id={clip.id}
        enabled={enableDrag}
        item={{ type: DragType.asset, asset: clip, index, assetId: clip.assetId }}
        onDragStart={onDragStart}
        index={index}
      >
        <DNDParentAsset asset={clip} dropLocation="asset" style={{ height: '100%' }}>
          <DropHighlight />
          <AssetGalleryCard clip={clip} {...rest} />
          <DraggingOverlay itemId={clip.id} type={DragType.asset} />
        </DNDParentAsset>
      </DNDMovableToBoardItem>
    );
  },
);

DNDAssetGalleryCard.displayName = 'DNDAssetGalleryCard';
