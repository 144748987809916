interface MobilePageTitleProps {
  title?: string;
  isCentered: boolean;
}

export const MobilePageTitle = ({ title = '', isCentered }: MobilePageTitleProps) => (
  <div className="flex flex-1 items-center">
    {isCentered ? (
      <h1 className="mx-auto pr-10 text-18 font-medium text-grey-12">{title}</h1>
    ) : (
      <div className="relative top-0 text-14 text-grey-12 md:top-0.5">{title}</div>
    )}
  </div>
);
