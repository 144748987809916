import { memo } from 'react';

import { AudioSampleRatesSelect } from '~/components/Filters/AudioSampleRateFilter/components/AudioSampleRatesSelect';
import { BaseStringSelectProps } from '~/components/StringSelect';
import { usePrivateAudioSampleRatesList } from '~/swr-hooks/filters/usePrivateAudioSampleRatesList';

interface PrivateAudioSampleRatesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateAudioSampleRatesSelect = memo(
  ({ boardId, libraryId, ...props }: PrivateAudioSampleRatesSelectProps) => {
    const { data = [], isLoading } = usePrivateAudioSampleRatesList({
      boardId,
      libraryId,
    });

    return <AudioSampleRatesSelect isLoading={isLoading} audioRates={data} {...props} />;
  },
);

PrivateAudioSampleRatesSelect.displayName = 'PrivateAudioSampleRatesSelect';
