import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { PrivateStatesSelect } from '~/components/Filters/StateFilter/components/PrivateStatesSelect';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

interface PrivateCitiesFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateStateFilterCard = memo(({ boardId, libraryId, ...props }: PrivateCitiesFilterCardProps) => {
  const renderStatesSelect = useCallback(
    (props: BaseStringSelectProps) => <PrivateStatesSelect boardId={boardId} libraryId={libraryId} {...props} />,
    [boardId, libraryId],
  );

  return <StringArrayFilterCard filter="states" filterCardType="state" StringSelect={renderStatesSelect} {...props} />;
});

PrivateStateFilterCard.displayName = 'PrivateStateFilterCard';
