import { Box } from '@air/zephyr';
import styled from 'styled-components';

export const dropHighlightOffset = -6;

export const DropHighlight = styled(Box)`
  border: 3px solid transparent;
  border-radius: 6px;
  position: absolute;
  // this element is inside BoardCard element to have proper drop area, but we want border outside of it
  left: ${dropHighlightOffset}px;
  right: ${dropHighlightOffset}px;
  top: ${dropHighlightOffset}px;
  bottom: ${dropHighlightOffset}px;
  box-sizing: border-box;
  pointer-events: none;
  transition: border-color 0.15s ease-in;

  .hovering-drop & {
    border-color: var(--colors-blue9);
    background-color: var(--colors-grey3);
  }
`;
