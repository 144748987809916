import { useMemo } from 'react';

import { HEADER_DESKTOP_HORIZONTAL_PADDING, HEADER_MOBILE_HORIZONTAL_PADDING } from '~/constants/WorkspaceSpacing';

export function usePrivateTableHeaderPadding() {
  const headerHorizontalPadding = useMemo(
    () => [HEADER_MOBILE_HORIZONTAL_PADDING, HEADER_MOBILE_HORIZONTAL_PADDING, HEADER_DESKTOP_HORIZONTAL_PADDING],
    [],
  );

  return { headerHorizontalPadding };
}
