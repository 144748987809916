import { Clip } from '@air/api/types';
import { noop } from 'lodash';
import { memo } from 'react';

import { DraggingOverlay } from '~/components/Gallery/DraggingOverlay';
import { FileGalleryCard, FileGalleryCardProps } from '~/components/Gallery/FileGalleryCard/FileGalleryCard';
import { DNDParentAsset } from '~/components/Shared/Drag/DNDParentAsset';
import DragType from '~/components/Shared/Drag/dragTypes';
import { DragDroppableFileShell, DragDroppableFileShellProps } from '~/components/Shared/DragDroppableFileShell';
import { DropHighlight } from '~/components/Shared/DropHighlight';

export interface DNDFileGalleryCardProps
  extends FileGalleryCardProps<Clip>,
    Pick<DragDroppableFileShellProps, 'onDragStart'> {
  enableDrag?: boolean;
  index: number;
}

const highlightPosition = {
  left: -3,
  top: -3,
  bottom: -3,
  right: -11,
};

export const DNDFileGalleryCard = memo(
  ({ enableDrag = true, file, index, onDragStart = noop, ...rest }: DNDFileGalleryCardProps) => {
    return (
      <DragDroppableFileShell file={file} onDragStart={onDragStart} index={index} enabled={enableDrag}>
        <DNDParentAsset asset={file} dropLocation="file" style={{ height: '100%' }}>
          <DropHighlight style={{ ...highlightPosition }} />
          <FileGalleryCard {...rest} file={file} />
          <DraggingOverlay itemId={file.id} type={DragType.file} style={highlightPosition} />
        </DNDParentAsset>
      </DragDroppableFileShell>
    );
  },
);

DNDFileGalleryCard.displayName = 'DNDFileGalleryCard';
