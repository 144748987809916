import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';
import { PrivateVideoFrameRatesSelect } from '~/components/Filters/VideoFrameRateFilter/components/PrivateVideoFrameRatesSelect';

interface PrivateVideoFrameRateFilterProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateVideoFrameRateFilterCard = memo(
  ({ boardId, libraryId, ...props }: PrivateVideoFrameRateFilterProps) => {
    const renderVideoFrameRateSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
      (props) => <PrivateVideoFrameRatesSelect boardId={boardId} libraryId={libraryId} {...props} />,
      [boardId, libraryId],
    );

    return (
      <StringArrayFilterCard
        filter="videoFrameRates"
        filterCardType="videoFrameRate"
        StringSelect={renderVideoFrameRateSelect}
        {...props}
      />
    );
  },
);

PrivateVideoFrameRateFilterCard.displayName = 'PrivateVideoFrameRateFilterCard';
