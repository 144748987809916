import { AirActionTrackingLocation } from '@air/analytics';
import { AssetCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldPlainText,
  CustomFieldPlainTextProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldPlainText';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateAssetCustomFieldPlainTextProps extends Omit<CustomFieldPlainTextProps, 'onSubmit'> {
  field: AssetCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  assetId: string;
}

export const PrivateAssetCustomFieldPlainText = memo(
  ({ field, trackingLocation, canEditCustomFields, assetId, ...rest }: PrivateAssetCustomFieldPlainTextProps) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;

    const onSubmit = useCallback(
      async (value: string) => {
        if (!workspaceId) {
          return;
        }

        await updateItemsCustomFieldValue({
          assetIds: [assetId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
          workspaceId,
        });
      },
      [updateItemsCustomFieldValue, assetId, field, trackingLocation, workspaceId],
    );

    return <CustomFieldPlainText {...rest} onSubmit={canEditCustomFields ? onSubmit : undefined} field={field} />;
  },
);

PrivateAssetCustomFieldPlainText.displayName = 'PrivateAssetCustomFieldPlainText';
