import { Upload } from '@air/redux-uploader';
import { TXProp } from '@air/zephyr';
import React, { CSSProperties, memo } from 'react';
import isEqual from 'react-fast-compare';

import { MobileTableRowContainer } from '~/components/TableView/MobileTableRow/MobileTableRowContainer';
import { UploadNameCell } from '~/components/TableView/NameCell/UploadNameCell';

export interface MobileUploadTableRowProps {
  upload: Upload;
  className?: string;
  /**
   * `react-virtualized` warns that `style` property should be included in the rendered cell for positioning
   * so we've separated it from the `tx` prop
   * */
  style?: CSSProperties;
  tx?: TXProp;
}

const PREVIEW_SIZE = 64;

export const MobileUploadTableRow = memo(
  ({ upload, ...props }: MobileUploadTableRowProps) => (
    <MobileTableRowContainer {...props}>
      <UploadNameCell upload={upload} imageSize={PREVIEW_SIZE} />
    </MobileTableRowContainer>
  ),
  isEqual,
);

MobileUploadTableRow.displayName = 'MobileUploadTableRow';
