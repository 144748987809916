import { FiltersTrackingLocation } from '@air/analytics';
import { memo, useCallback } from 'react';

import { PrivateAudioCodingFilterCard } from '~/components/Filters/AudioCodingFilter/PrivateAudioCodingFilterCard';
import { PrivateAudioSampleRateFilterCard } from '~/components/Filters/AudioSampleRateFilter/PrivateAudioSampleRateFilterCard';
import { PrivateCamerasFilterCard } from '~/components/Filters/CamerasFilter/PrivateCamerasFilterCard';
import { PrivateCityFilterCard } from '~/components/Filters/CityFilter/PrivateCityFilterCard';
import { ColorFilterCard, ColorFilterCardProps } from '~/components/Filters/ColorFilter/ColorFilterCard';
import { PrivateCopyrightFilterCard } from '~/components/Filters/CopyrightFilter/PrivateCopyrightFilterCard';
import { PrivateCountryFilterCard } from '~/components/Filters/CountryFilter/PrivateCountryFilterCard';
import { PrivateCreatorFilterCard } from '~/components/Filters/CreatorFilter/PrivateCreatorFilterCard';
import { PrivateCustomFieldFilterCard } from '~/components/Filters/CustomFieldFilter/PrivateCustomFieldFilterCard';
import { PrivateExtensionsFilterCard } from '~/components/Filters/ExtensionFilter/PrivateExtensionsFilterCard';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { FiltersDropdown, FiltersDropdownProps } from '~/components/Filters/FiltersDropdown/FiltersDropdown';
import { ImportedKeywordsFilterCard } from '~/components/Filters/ImportedKeywordsFilter/ImportedKeywordsFilterCard';
import { PrivateSourceFilterCard } from '~/components/Filters/SourceFilter/PrivateSourceFilterCard';
import { PrivateStateFilterCard } from '~/components/Filters/StateFilter/PrivateStateFilterCard';
import { PrivateTagsFiltersCard } from '~/components/Filters/TagsFilter/PrivateTagsFilterCard';
import { UploaderFilterCard, UploaderFilterCardProps } from '~/components/Filters/UploaderFilter/UploaderFilterCard';
import { PrivateVideoAspectRatioFilterCard } from '~/components/Filters/VideoAspectRatioFilter/PrivateVideoAspectRatioFilterCard';
import { PrivateVideoFrameRateFilterCard } from '~/components/Filters/VideoFrameRateFilter/PrivateVideoFrameRateFilterCard';
import { useDidFilterChange } from '~/components/SavedFilter/shared/hooks/useDidFilterChange';
import { useAppliedCustomFieldsFilters } from '~/hooks/filters/useAppliedCustomFieldsFilters';
import { useAppliedTagsFilters } from '~/hooks/filters/useAppliedTagsFilters';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useFetchAnyWorkspacePermissions } from '~/hooks/useFetchAnyWorkspacePermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { useGetPrivateFilterOptions } from '~/swr-hooks/filters/useGetPrivateFilterOptions';
import { useGetPrivateFiltersEntities } from '~/swr-hooks/filters/useGetPrivateFiltersEntities';
import { canSeeCustomFields, canSeeWorkspaceTags } from '~/utils/permissions/workspacePermissions';

export interface PrivateFiltersDropdownProps
  extends Pick<
    FiltersDropdownProps,
    'otherFilters' | 'availableFilters' | 'onApplyClick' | 'title' | 'customFields' | 'availableTypes' | 'secondaryCTA'
  > {
  trackLocation: FiltersTrackingLocation;
}

export const PrivateFiltersDropdown = memo((props: PrivateFiltersDropdownProps) => {
  const boardId = useURLBoardIdSelector();
  const libraryId = useURLLibraryIdSelector();
  const { data: filterOptions } = useGetPrivateFilterOptions(boardId); // prefetch filter options

  const { appliedTagsFilters } = useAppliedTagsFilters();
  const { appliedCustomFieldsFilters } = useAppliedCustomFieldsFilters();
  const { data: entities } = useGetPrivateFiltersEntities({
    tagLabels: appliedTagsFilters,
    customFields: appliedCustomFieldsFilters,
  });
  const { isFilterChanged } = useDidFilterChange({ options: filterOptions, entities });
  const { data: permissions } = useFetchAnyWorkspacePermissions();
  const { boardPermissions } = useCurrentBoardPermissions();
  const canViewCustomFields = canSeeCustomFields(boardId ? boardPermissions : permissions);
  const canSeeTags = canSeeWorkspaceTags(permissions);

  const renderColorFilterCard = useCallback(
    (props: Omit<ColorFilterCardProps, 'colors'>) => (
      <ColorFilterCard colors={filterOptions?.colors ?? []} {...props} />
    ),
    [filterOptions?.colors],
  );

  const renderSourceFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateSourceFilterCard boardId={boardId} libraryId={libraryId} {...props} />,
    [boardId, libraryId],
  );

  const renderExtensionFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateExtensionsFilterCard boardId={boardId} libraryId={libraryId} {...props} />,
    [boardId, libraryId],
  );

  const renderUploaderFilterCard = useCallback(
    (props: Omit<UploaderFilterCardProps, 'uploaders'>) => (
      <UploaderFilterCard uploaders={filterOptions?.uploaders ?? []} {...props} />
    ),
    [filterOptions?.uploaders],
  );

  const renderImportedKeywordsFilterCard = useCallback(
    (props: BaseFilterCardProps) => <ImportedKeywordsFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderCopyRightFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateCopyrightFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderCreatorFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateCreatorFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderCamerasFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateCamerasFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderCountryFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateCountryFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderCityFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateCityFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderStateFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateStateFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderVideoFrameRateFilterCard = useCallback(
    (props: BaseFilterCardProps) => (
      <PrivateVideoFrameRateFilterCard libraryId={libraryId} boardId={boardId} {...props} />
    ),
    [boardId, libraryId],
  );

  const renderVideoAspectRatioFilterCard = useCallback(
    (props: BaseFilterCardProps) => (
      <PrivateVideoAspectRatioFilterCard libraryId={libraryId} boardId={boardId} {...props} />
    ),
    [boardId, libraryId],
  );

  const renderAudioCodingFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PrivateAudioCodingFilterCard libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderAudioSampleRateFilterCard = useCallback(
    (props: BaseFilterCardProps) => (
      <PrivateAudioSampleRateFilterCard libraryId={libraryId} boardId={boardId} {...props} />
    ),
    [boardId, libraryId],
  );

  return (
    <FiltersDropdown
      ColorFilterCard={renderColorFilterCard}
      TagsFilterCard={canSeeTags ? PrivateTagsFiltersCard : undefined}
      CameraFilterCard={renderCamerasFilterCard}
      SourceFilterCard={renderSourceFilterCard}
      UploaderFilterCard={renderUploaderFilterCard}
      CreatorFilterCard={renderCreatorFilterCard}
      CopyrightFilterCard={renderCopyRightFilterCard}
      CustomFieldFilterCard={canViewCustomFields ? PrivateCustomFieldFilterCard : undefined}
      ExtensionFilterCard={renderExtensionFilterCard}
      CountryFilterCard={renderCountryFilterCard}
      CityFilterCard={renderCityFilterCard}
      StateFilterCard={renderStateFilterCard}
      ImportedKeywordsFilterCard={renderImportedKeywordsFilterCard}
      VideoFrameRateFilterCard={renderVideoFrameRateFilterCard}
      VideoAspectRatioFilterCard={renderVideoAspectRatioFilterCard}
      AudioCodingFilterCard={renderAudioCodingFilterCard}
      AudioSampleRateFilterCard={renderAudioSampleRateFilterCard}
      isFilterChanged={isFilterChanged}
      {...props}
    />
  );
});

PrivateFiltersDropdown.displayName = 'PrivateFiltersDropdown';
