import { memo } from 'react';

import { VideoFrameRatesSelect } from '~/components/Filters/VideoFrameRateFilter/components/VideoFrameRatesSelect';
import { BaseStringSelectProps } from '~/components/StringSelect';
import { usePrivateVideoFrameRatesList } from '~/swr-hooks/filters/usePrivateVideoFrameRatesList';

interface PrivateVideoFrameRatesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateVideoFrameRatesSelect = memo(
  ({ boardId, libraryId, ...props }: PrivateVideoFrameRatesSelectProps) => {
    const { data = [], isLoading } = usePrivateVideoFrameRatesList({
      boardId,
      libraryId,
    });

    return <VideoFrameRatesSelect isLoading={isLoading} frameRates={data} {...props} />;
  },
);

PrivateVideoFrameRatesSelect.displayName = 'PrivateVideoFrameRatesSelect';
