import { Search } from '@air/api';
import { Board, WorkspaceInfo } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const PRIVATE_FILTER_OPTIONS = 'PRIVATE_FILTER_OPTIONS';

export const getPrivateFiltersOptionsKey = ({
  workspaceId,
  boardId,
}: {
  workspaceId?: WorkspaceInfo['id'];
  boardId?: Board['id'];
}) => [PRIVATE_FILTER_OPTIONS, { workspaceId, boardId }];

export const useGetPrivateFilterOptions = (boardId?: Board['id']) => {
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery({
    queryKey: getPrivateFiltersOptionsKey({ workspaceId: currentWorkspace?.id, boardId }),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Search.getFilterLookups({ workspaceId, boardId });
    },
    enabled: !!currentWorkspace?.id,
  });
};
