import { memo, useCallback } from 'react';

import { PrivateCitiesSelect } from '~/components/Filters/CityFilter/components/PrivateCitiesSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PrivateCitiesFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCityFilterCard = memo(({ boardId, libraryId, ...props }: PrivateCitiesFilterCardProps) => {
  const renderCitiesSelect = useCallback(
    (props: BaseStringSelectProps) => <PrivateCitiesSelect libraryId={libraryId} boardId={boardId} {...props} />,
    [libraryId, boardId],
  );

  return <StringArrayFilterCard filter="cities" filterCardType="city" StringSelect={renderCitiesSelect} {...props} />;
});

PrivateCityFilterCard.displayName = 'PrivateCityFilterCard';
