import { Clip } from '@air/api/types';
import { isEqual } from 'lodash';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { GalleryViewRender } from '~/components/Gallery/types';
import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { DNDFileGalleryCard } from '~/components/PrivateGallery/DNDFileGalleryCard';
import { QueryParamNames } from '~/constants/search';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { canSeeAssetCustomFields, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { useGetPrivateAssetMenuOptionsCallback } from './useGetPrivateAssetMenuOptionsCallback';

type PrivateGalleryFileProps = GalleryViewRender<Clip>;

export const PrivateGalleryFile = memo(
  ({ data: file, box, index }: PrivateGalleryFileProps) => {
    const { clipPermissions } = useClipPermissions({
      clipId: file.id,
    });
    const { customFields } = usePrivateCustomFieldsContext();
    const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
    const { getAssetMenuOptionsCallback } = useGetPrivateAssetMenuOptionsCallback();
    const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: file });
    const { goToAssetPage } = useGoToAssetPage();
    const { openAssetInNewTab } = useOpenAssetInNewTab();
    const urlLibraryId = useURLLibraryIdSelector();
    const libraryId = useSelector(centralizedBoardLibraryIdSelector);
    const { data: libraryCustomFieldsVisbility } = useLibraryCustomFields({ libraryId: libraryId || urlLibraryId });
    const filteredCustomFields = !!libraryCustomFieldsVisbility?.data.length
      ? customFields?.filter(
          (customField) => !!libraryCustomFieldsVisbility?.data.find((field) => field.id === customField.id)?.visible,
        )
      : customFields;

    const onCustomFieldsClick = useCallback(
      () =>
        goToAssetPage({
          asset: file,
          query: {
            [QueryParamNames.tab]: AssetModalPanel.FIELDS,
          },
          trackLocation: 'custom-field-click',
        }),
      [file, goToAssetPage],
    );

    const onDiscussionsClick = useCallback(
      () =>
        goToAssetPage({
          asset: file,
          query: {
            [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
          },
          trackLocation: 'comments-click',
        }),
      [file, goToAssetPage],
    );

    const onVersionsClick = useCallback(
      () =>
        goToAssetPage({
          asset: file,
          query: {
            [QueryParamNames.versions]: true,
          },
          trackLocation: 'versions-click',
        }),
      [file, goToAssetPage],
    );

    const onItemClick = useCallback(
      () =>
        goToAssetPage({
          asset: file,
          trackLocation: 'card-click',
        }),
      [file, goToAssetPage],
    );
    const onItemCmdClick = useCallback(
      () =>
        openAssetInNewTab({
          asset: file,
          trackLocation: 'card-click',
        }),
      [file, openAssetInNewTab],
    );

    const canViewCustomFields = canSeeAssetCustomFields(clipPermissions);
    const canSeeDiscussions = canViewAssetDiscussions(clipPermissions);

    return (
      <DNDFileGalleryCard
        cardWidth={box.width}
        cardHeight={box.height}
        file={file}
        enableDrag={!isMobileAgent}
        index={index}
        isSelectable={true}
        key={file.id}
        onFileClick={onItemClick}
        onFileTitleCmdClick={onItemCmdClick}
        onCustomFieldsClick={onCustomFieldsClick}
        onDiscussionsClick={canSeeDiscussions ? onDiscussionsClick : undefined}
        onVersionsClick={!!file.assetVersionCount && file?.assetVersionCount > 1 ? onVersionsClick : undefined}
        getItemMenuOptions={_getItemMenuOptions}
        getSelectionMenuOptions={getSelectionMenuOptions}
        canViewCustomFields={canViewCustomFields}
        allCustomFields={filteredCustomFields}
      />
    );
  },
  // `container` comes from `react-virtualized` and is a different object every time but may have the same values so we need to deep equal check the props
  isEqual,
);

PrivateGalleryFile.displayName = 'PrivateGalleryFile';
