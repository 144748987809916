import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateAudioCodingsList } from '~/swr-hooks/filters/usePrivateAudioCodingsList';

interface PrivateAudioCodingsSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateAudioCodingsSelect = memo(({ boardId, libraryId, ...props }: PrivateAudioCodingsSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePrivateAudioCodingsList({
    search: debouncedSearchValue,
    boardId,
    libraryId,
  });

  return <StringSelect isLoading={isLoading} values={data} onInputChange={setSearchValue} {...props} />;
});

PrivateAudioCodingsSelect.displayName = 'PrivateAudioCodingsSelect';
