import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { PrivateSourcesSelect } from '~/components/Filters/SourceFilter/components/PrivateSourcesSelect';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';

interface PrivateSourceFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateSourceFilterCard = memo(({ boardId, libraryId, ...props }: PrivateSourceFilterCardProps) => {
  const renderSourceSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
    (props) => <PrivateSourcesSelect boardId={boardId} libraryId={libraryId} {...props} />,
    [boardId, libraryId],
  );

  return (
    <StringArrayFilterCard filter="sources" filterCardType="source" StringSelect={renderSourceSelect} {...props} />
  );
});

PrivateSourceFilterCard.displayName = 'PrivateSourceFilterCard';
