import { ViewTypeName } from '@air/api/types';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { ViewKanbanGroupByMenu } from '~/components/ViewControls/ViewKanbanGroupByMenu/ViewKanbanGroupByMenu';
import { ViewSortingMenu } from '~/components/ViewControls/ViewSortingMenu/ViewSortingMenu';
import { ViewTypeToggleMenu } from '~/components/ViewControls/ViewTypeToggleMenu/ViewTypeToggleMenu';
import { PrivateViewVisibilityToggleMenu } from '~/components/ViewControls/ViewVisiblityToggleMenu/PrivateViewVisibilityToggleMenu';
import { ONE_HOUR } from '~/constants/unitsOfTime';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { currentKanbanGroupByCustomFieldIdSelector, currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export type PrivateViewControlsButtonsProps = {
  showViewSortingMenu?: boolean;
};

export const PrivateViewControlsButtons = memo(({ showViewSortingMenu = true }: PrivateViewControlsButtonsProps) => {
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const inGalleryView = currentViewTypeName === ViewTypeName.gallery;
  const inKanbanView = currentViewTypeName === ViewTypeName.kanban;
  const { boardPermissions } = useCurrentBoardPermissions();
  const canViewCustomFields = canSeeBoardCustomFields(boardPermissions);
  const customFieldId = useSelector(currentKanbanGroupByCustomFieldIdSelector);
  const { data: options } = useGetPrivateCustomFieldOptions({
    customFieldId,
    staleTime: ONE_HOUR,
  });

  return (
    <div className="flex items-center gap-2">
      {inKanbanView && <ViewKanbanGroupByMenu customFieldValues={options?.data} />}

      <div className="flex items-center gap-2">
        {(inGalleryView || inKanbanView) && showViewSortingMenu && <ViewSortingMenu />}
        <PrivateViewVisibilityToggleMenu />
        <ViewTypeToggleMenu canViewCustomFields={canViewCustomFields} />
      </div>
    </div>
  );
});

PrivateViewControlsButtons.displayName = 'PrivateViewControlsButtons';
