import { hasSelectedItemsSelector } from '@air/redux-selected-items';
import { useCallback, useEffect, useState } from 'react';
import { useDragDropManager } from 'react-dnd';

import DragType, { isBoardDragType, RearrangableItemType } from '~/components/Shared/Drag/dragTypes';
import { selectedBoardsIdsSelector, selectedClipIdsSelector } from '~/store/selectedItems/selectors';
import { useAirStore } from '~/utils/ReduxUtils';

/**
 * This hook returns true if user is dragging an item:
 * - no items selected - returns true only for current item (if `isDragging` flag from react-dnd is true)
 * - user has selected a few items - returns true for all selected items
 */
export function useIsDragging(itemId: string, type: DragType) {
  const [isDragging, setIsDragging] = useState(false);
  const manager = useDragDropManager();
  const store = useAirStore();

  const updateIsDragging = useCallback(() => {
    const hasSelectedItems = hasSelectedItemsSelector(store.getState());
    const selectedClips = selectedClipIdsSelector(store.getState());
    const selectedBoards = selectedBoardsIdsSelector(store.getState());
    const monitor = manager.getMonitor();
    const isItemDragged = monitor.isDragging();
    let newIsDragging = false;

    const item = monitor.getItem() as RearrangableItemType;

    if (!isItemDragged) {
      newIsDragging = false;
      // if no item is selected, we drag current element - set isDragging to true
    } else if (!hasSelectedItems) {
      newIsDragging = item.id === itemId && item.type === type;
    } else {
      if (isBoardDragType(type) && selectedBoards.includes(itemId)) {
        newIsDragging = true;
      } else if ((type === DragType.file || type === DragType.asset) && selectedClips.includes(itemId)) {
        newIsDragging = true;
      } else {
        newIsDragging = false;
      }
    }

    if (isDragging !== newIsDragging) {
      setIsDragging(newIsDragging);
    }
  }, [store, manager, isDragging, itemId, type]);

  useEffect(() => {
    const unsubscribe = manager.getMonitor().subscribeToStateChange(updateIsDragging);

    return () => {
      unsubscribe();
    };
  }, [manager, updateIsDragging]);

  return isDragging;
}
