import { useBreakpointsContext } from '@air/provider-media-query';
import { memo } from 'react';

import { NoAssetsAddedDesktopAsset } from '~/components/ContributableNullState/NoAssetsAddedDesktopAsset';
import { NoAssetsAddedMobileAsset } from '~/components/ContributableNullState/NoAssetsAddedMobileAsset';
import { NullState, NullStateProps } from '~/components/NullState';

export interface ContributableNullStateProps extends Omit<NullStateProps, 'image' | 'description'> {
  description?: string;
}

const DEFAULT_DESCRIPTION = 'Upload from your computer via drag-and-drop, or click the button below';

export const ContributableNullState = memo(
  ({ title, cta, description = DEFAULT_DESCRIPTION }: ContributableNullStateProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();
    return (
      <NullState
        image={
          <div>
            {isAboveMediumScreen ? (
              <NoAssetsAddedDesktopAsset className="mx-auto -mb-4 w-full" />
            ) : (
              <NoAssetsAddedMobileAsset className="mx-auto" />
            )}
          </div>
        }
        title={title}
        description={description}
        cta={cta}
      />
    );
  },
);

ContributableNullState.displayName = 'ContributableNullState';
