import { memo } from 'react';

import { NoSearchResultsInfo } from '~/components/NoSearchResults/shared/NoSearchResultsInfo';
import { Routes } from '~/constants/routes';
import { pushWithExistingQuery } from '~/utils/PathUtils';

export const SearchInAllAssetsHint = memo(() => (
  <NoSearchResultsInfo>
    Check for typos or filters, or search from{' '}
    <button
      className="text-blue-9 hover:underline"
      data-testid="SEARCH_IN_ALL_ASSETS"
      onClick={() => pushWithExistingQuery({ path: Routes.media.all })}
    >
      All assets & files
    </button>{' '}
    to look across the workspace.
  </NoSearchResultsInfo>
));

SearchInAllAssetsHint.displayName = 'SearchInAllAssetsHint';
