import { EditableText, EditableTextProps } from '@air/zephyr-editable-text';
import { noop } from 'lodash';
import React, { memo } from 'react';

import { TABLE_ASSET_TITLE } from '~/constants/testIDs';

export interface EditableTableTitleProps {
  title: string;
  setIsRenaming?: (nextIsRenaming: boolean) => void;
  onRenameComplete: EditableTextProps['onSubmit'];
}

const EDITING_CLEARANCE = 200;
const EDITING_MIN_HEIGHT = 24;

export const EditableTableTitle = memo(({ title, setIsRenaming = noop, onRenameComplete }: EditableTableTitleProps) => (
  <EditableText
    required
    onReset={() => {
      setIsRenaming(false);
    }}
    data-testid={TABLE_ASSET_TITLE}
    isSingleLine
    label="Give it a name"
    value={title}
    isEditing={true}
    onEditingStateChange={setIsRenaming}
    onSubmit={onRenameComplete}
    tx={{
      EditableTextText: {
        fontSize: 14,
        minWidth: EDITING_CLEARANCE,
        wordBreak: 'break-word',
        fontWeight: 'medium',
        minHeight: EDITING_MIN_HEIGHT,
      },
      mt: -2,
      ml: -8,
    }}
  />
));

EditableTableTitle.displayName = 'EditableTableTitle';
