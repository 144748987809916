import { AirActionTrackingLocation, useTrackClickedCollectContent } from '@air/analytics';
import { useAirModal } from '@air/provider-modal';
import { useSysadmin } from '@air/sysadmin';
import { useCallback, useMemo } from 'react';

import { ContentCollectionModal } from '~/components/Modals/ContentCollectionModal/ContentCollectionModal';
import { IMPORT_SOURCES, INTERNAL_IMPORT_SOURCES} from '~/constants/importSource';
import { useCreateSubBoard } from '~/hooks/useCreateSubBoard';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useImportFrom } from '~/hooks/useImportFrom';
import { useUploadFilesFromComputer } from '~/hooks/useUploadFilesFromComputer';
import { useUploadFoldersFromComputer } from '~/hooks/useUploadFoldersFromComputer';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { getAddMenuOptions } from '~/utils/dropdownMenuOptions/getAddMenuOptions';
import { ImportSourceType } from '~/utils/ImportUtils';
import { getActionMenuOptions } from '~/utils/menuOptions/getActionMenuOptions';
import { canCreateAsset } from '~/utils/permissions/assetPermissions';
import { canCreateBoard, canSeeBoardContentCollectionForm } from '~/utils/permissions/boardPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

export const usePrivateBoardActions = ({ trackLocation }: { trackLocation: AirActionTrackingLocation }) => {
  const { uploadFilesFromComputer } = useUploadFilesFromComputer({ trackLocation });
  const { uploadFoldersFromComputer } = useUploadFoldersFromComputer({ trackLocation });
  const { importFrom } = useImportFrom({ trackLocation });
  const { createSubBoard } = useCreateSubBoard();
  const { boardPermissions } = useCurrentBoardPermissions();
  const store = useAirStore();
  const { isInternal } = useSysadmin();

  const canAddSubBoard = canCreateBoard(boardPermissions);
  const canAddAssets = canCreateAsset(boardPermissions);
  const canManageLinks = canSeeBoardContentCollectionForm(boardPermissions);

  const [showContentCollectionModal] = useAirModal(ContentCollectionModal);

  const { trackClickedCollectContent } = useTrackClickedCollectContent();

  const exposeImportSources = useMemo(() => {
    let sources: ImportSourceType[] = IMPORT_SOURCES;
    if (isInternal) sources = sources.concat(INTERNAL_IMPORT_SOURCES);
    return sources;
  }, [isInternal]);

  const onCollectContentClick = useCallback(() => {
    const currentBoard = centralizedBoardSelector(store.getState());
    if (!currentBoard) return;
    trackClickedCollectContent();
    showContentCollectionModal({ boardId: currentBoard?.id, boardTitle: currentBoard.title });
  }, [showContentCollectionModal, store, trackClickedCollectContent]);

  const privateBoardActionsOptions = useMemo(
    () =>
      getAddMenuOptions({
        onAddSubBoard: canAddSubBoard ? () => createSubBoard({ trackLocation }) : undefined,
        onImportFrom: canAddAssets && canAddSubBoard ? importFrom : undefined,
        onUploadFilesFromComputer: canAddAssets ? uploadFilesFromComputer : undefined,
        onUploadFoldersFromComputer: canAddAssets && canAddSubBoard ? uploadFoldersFromComputer : undefined,
        onCollectContent: canManageLinks ? onCollectContentClick : undefined,
        exposeImportSources,
      }),
    [
      canAddAssets,
      canAddSubBoard,
      canManageLinks,
      createSubBoard,
      importFrom,
      onCollectContentClick,
      trackLocation,
      uploadFilesFromComputer,
      uploadFoldersFromComputer,
      exposeImportSources,
    ],
  );

  const options = useMemo(
    () =>
      getActionMenuOptions({
        onAddSubBoard: canAddSubBoard ? () => createSubBoard({ trackLocation }) : undefined,
        onImportFrom: canAddAssets && canAddSubBoard ? importFrom : undefined,
        onUploadFilesFromComputer: canAddAssets ? uploadFilesFromComputer : undefined,
        onUploadFoldersFromComputer: canAddAssets && canAddSubBoard ? uploadFoldersFromComputer : undefined,
        onCollectContent: canManageLinks ? onCollectContentClick : undefined,
        exposeImportSources,
      }),
    [
      canAddAssets,
      canAddSubBoard,
      canManageLinks,
      createSubBoard,
      importFrom,
      onCollectContentClick,
      trackLocation,
      uploadFilesFromComputer,
      uploadFoldersFromComputer,
      exposeImportSources,
    ],
  );

  return {
    privateBoardActionsOptions,
    options,
  };
};
