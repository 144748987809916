import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { FilterLogicSelect } from '~/components/Filters/FilterLogicSelect';
import { ImportedKeywordsSelect } from '~/components/Filters/ImportedKeywordsFilter/ImportedKeywordsSelect';
import {
  clearImportedKeywordsFilterValuesAction,
  removeFilterAction,
  setImportedKeywordsFilterLogicAction,
  setImportedKeywordsFiltersValueAction,
} from '~/store/filters/actions';
import {
  selectedImportedKeywordsFilterLogicSelector,
  selectedImportedKeywordsFilterValuesSelector,
} from '~/store/filters/selectors';
import { FilterLogicType, SelectedFilter } from '~/store/filters/types';

export interface ImportedKeywordsFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const ImportedKeywordsFilterCard = memo(({ boardId, libraryId, ...props }: ImportedKeywordsFilterCardProps) => {
  const dispatch = useDispatch();

  const clearValues = useCallback(() => {
    dispatch(clearImportedKeywordsFilterValuesAction());
  }, [dispatch]);

  const selectedLogic = useSelector(selectedImportedKeywordsFilterLogicSelector);
  const selectedKeywords = useSelector(selectedImportedKeywordsFilterValuesSelector);

  const onLogicChange = useCallback(
    (logic: FilterLogicType) => dispatch(setImportedKeywordsFilterLogicAction({ logic })),
    [dispatch],
  );

  const onSelectedKeywordsChanged = useCallback(
    (keywords: string[]) => dispatch(setImportedKeywordsFiltersValueAction({ keywords })),
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="importedKeywords" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <FilterLogicSelect selectedLogic={selectedLogic} onLogicChange={onLogicChange} />
      <ImportedKeywordsSelect
        libraryId={libraryId}
        boardId={boardId}
        selectedKeywords={selectedKeywords}
        onSelectedKeywordsChange={onSelectedKeywordsChanged}
      />
    </FilterCard>
  );
});

ImportedKeywordsFilterCard.displayName = 'ImportedKeywordsFilterCard';
