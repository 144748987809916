import { Search } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const getPrivateVideoFrameRatesListKey = ({
  workspaceId,
  boardId,
  libraryId,
}: {
  workspaceId: string | undefined;
  boardId: string | undefined;
  libraryId: string | undefined;
}) => ['PRIVATE_VIDEO_FRAME_RATES', { workspaceId, boardId, libraryId }];

export const usePrivateVideoFrameRatesList = ({
  boardId,
  libraryId,
}: {
  boardId: string | undefined;
  libraryId: string | undefined;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getPrivateVideoFrameRatesListKey({ workspaceId: currentWorkspace?.id, boardId, libraryId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(currentWorkspace?.id, 'Workspace ID is required');

      const data = await Search.getAssetVersionFilterLookups({
        limit: 50,
        workspaceId: currentWorkspace.id,
        field: 'videoFrameRate',
        boardId,
        libraryId,
      });

      return data.lookups.map(({ value }) => value.toString());
    },
    enabled: !!currentWorkspace?.id,
  });
};
