import { Search } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const getPrivateCameraModelsListKey = ({
  workspaceId,
  search,
  boardId,
  make,
  libraryId,
}: {
  workspaceId: string | undefined;
  search: string;
  boardId: string | undefined;
  make: string | undefined;
  libraryId: string | undefined;
}) => ['PRIVATE_CAMERA_MODELS', { workspaceId, search, boardId, make, libraryId }];

export const usePrivateCameraModelsList = ({
  search,
  boardId,
  make,
  libraryId,
}: {
  search: string;
  boardId: string | undefined;
  make: string | undefined;
  libraryId: string | undefined;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getPrivateCameraModelsListKey({ workspaceId: currentWorkspace?.id, search, boardId, make, libraryId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(currentWorkspace?.id, 'Workspace ID is required');

      const data = await Search.getAssetVersionFilterLookups({
        limit: 20,
        boardId,
        field: 'camera.model',
        camera: make ? { make } : undefined,
        workspaceId: currentWorkspace.id,
        search,
        libraryId,
      });

      return data.lookups.map((lookup) => lookup.value.toString());
    },
    enabled: !!currentWorkspace?.id,
  });
};
