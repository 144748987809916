import { ChevronDown } from '@air/next-icons';
import { input } from '@air/primitive-input';
import { capitalize } from 'lodash';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ThumbnailPreference, useThumbnailPreference } from '~/hooks/useThumbnailPreference';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';

const THUMBNAIL_PREFERENCES: ThumbnailPreference[] = ['default', 'contain', 'fill'];

export const ViewVisibilityThumbnailPreference = memo(() => {
  const { getPreference, updatePreference } = useThumbnailPreference();
  const boardId = useSelector(CentralizedBoardSelectors.boardId);

  const value = useMemo(() => {
    if (boardId) {
      return getPreference(boardId);
    }

    return '';
  }, [boardId, getPreference]);

  /**
   * Don't render this on non-board pages
   */
  if (!boardId) return null;

  return (
    <>
      <div className="flex items-center justify-between gap-6 px-2 py-1">
        <label className="text-14 text-grey-12" htmlFor="preferences">
          Thumbnail
        </label>
        <div className={input({ className: 'relative px-0 text-12', size: 'small' })}>
          <select
            id="preferences"
            defaultValue={value}
            className="h-full w-full cursor-pointer appearance-none bg-transparent pl-2 pr-8"
            onChange={(event) => {
              if (boardId) {
                updatePreference(boardId, event.target.value as ThumbnailPreference);
              }
            }}
            value={value}
          >
            {THUMBNAIL_PREFERENCES.map((option) => (
              <option key={option} value={option}>
                {capitalize(option.replace('-', ' '))}
              </option>
            ))}
          </select>
          <ChevronDown className="pointer-events-none absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2" />
        </div>
      </div>
    </>
  );
});

ViewVisibilityThumbnailPreference.displayName = 'ViewVisibilityThumbnailPreference';
