import { memo, useCallback } from 'react';

import { PrivateAudioCodingsSelect } from '~/components/Filters/AudioCodingFilter/components/PrivateAudioCodingsSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';

interface PrivateAudioCodingFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateAudioCodingFilterCard = memo(
  ({ boardId, libraryId, ...props }: PrivateAudioCodingFilterCardProps) => {
    const renderAudioCodingsSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
      (props) => <PrivateAudioCodingsSelect libraryId={libraryId} boardId={boardId} {...props} />,
      [boardId, libraryId],
    );

    return (
      <StringArrayFilterCard
        filter="audioCodings"
        filterCardType="audioCoding"
        StringSelect={renderAudioCodingsSelect}
        {...props}
      />
    );
  },
);

PrivateAudioCodingFilterCard.displayName = 'PrivateAudioCodingFilterCard';
