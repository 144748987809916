import { useTrackClickedAddContent } from '@air/analytics';
import { Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo } from 'react';

import { ContributableNullState } from '~/components/ContributableNullState/ContributableNullState';
import { NonContributableEmptyState } from '~/components/NonContributableNullState/NonContributableNullState';
import { EMPTY_BOARD_COPY } from '~/constants/testIDs';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useUploadFilesFromComputer } from '~/hooks/useUploadFilesFromComputer';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

import { PrivateBoardNoSearchResults } from './PrivateBoardNoSearchResults';
import { usePrivateBoardActions } from './usePrivateBoardActions';

export const PrivateBoardNullState = memo(() => {
  const trackLocation = 'board-container-null-state';
  const { uploadFilesFromComputer } = useUploadFilesFromComputer({ trackLocation });
  const { boardPermissions } = useCurrentBoardPermissions();
  const canAddSubboard = canCreateBoard(boardPermissions);
  const { privateBoardActionsOptions } = usePrivateBoardActions({ trackLocation });
  const { isSearchActive } = useIsSearchActive();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { trackClickedAddContent } = useTrackClickedAddContent();

  if (isSearchActive) return <PrivateBoardNoSearchResults />;

  if (isAboveMediumScreen && !!privateBoardActionsOptions.length) {
    return (
      <ContributableNullState
        title={EMPTY_BOARD_COPY}
        cta={
          <DropdownMenu
            size="small"
            trigger={
              <Button
                prefix={<Plus className="h-4 w-4" />}
                data-testid="CONTRIBUTABLE_NULL_STATE_CTA_BUTTON"
                appearance="filled"
                color="blue"
                size="large"
              >
                Add Content
              </Button>
            }
            onOpenChange={(isExpanded: boolean) => {
              if (isExpanded) trackClickedAddContent({ location: trackLocation });
            }}
          >
            {renderDropdownItems({ options: privateBoardActionsOptions, size: 'small' })}
          </DropdownMenu>
        }
      />
    );
  }

  if (!isAboveMediumScreen && canAddSubboard) {
    return (
      <ContributableNullState
        title={EMPTY_BOARD_COPY}
        cta={
          <Button
            prefix={<Plus className="h-4 w-4" />}
            data-testid="CONTRIBUTABLE_NULL_STATE_CTA_BUTTON"
            appearance="outline"
            color="blue"
            size="large"
            onClick={() => {
              uploadFilesFromComputer();
              trackClickedAddContent({ location: trackLocation });
            }}
          >
            Upload from your device
          </Button>
        }
      />
    );
  }

  return <NonContributableEmptyState />;
});

PrivateBoardNullState.displayName = 'PrivateBoardNullState';
