import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateVideoAspectRatiosList } from '~/swr-hooks/filters/usePrivateVideoAspectRatiosList';

interface PrivateVideoAspectRatiosSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateVideoAspectRatiosSelect = memo(
  ({ boardId, libraryId, ...props }: PrivateVideoAspectRatiosSelectProps) => {
    const [searchValue, setSearchValue] = useState('');

    const [debouncedSearchValue] = useDebounce(searchValue, 200, {
      trailing: true,
    });

    const { data = [], isLoading } = usePrivateVideoAspectRatiosList({
      search: debouncedSearchValue,
      boardId,
      libraryId,
    });

    return (
      <StringSelect
        placeholder="Search for signal aspect ratio i.e. 16:9"
        isLoading={isLoading}
        values={data}
        onInputChange={setSearchValue}
        {...props}
      />
    );
  },
);

PrivateVideoAspectRatiosSelect.displayName = 'PrivateVideoAspectRatiosSelect';
