import { AirActionTrackingLocation, useTrackClickedFolderUpload } from '@air/analytics';
import { UploadContext } from '@air/component-upload-dropzone';
import { useCallback, useContext } from 'react';

import { useActiveSubscriptionCallback } from '~/swr-hooks/subscriptions/useActiveSubscriptionCallback';

export const useUploadFoldersFromComputer = ({ trackLocation }: { trackLocation: AirActionTrackingLocation }) => {
  const { openUploader } = useContext(UploadContext);
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { trackClickedFolderUpload } = useTrackClickedFolderUpload();

  const uploadFoldersFromComputer = useCallback(
    () =>
      activeSubscriptionCallback(() => {
        openUploader({ type: 'folders' });
        trackClickedFolderUpload({ location: trackLocation });
      }),
    [activeSubscriptionCallback, openUploader, trackLocation, trackClickedFolderUpload],
  );

  return {
    uploadFoldersFromComputer,
  };
};
