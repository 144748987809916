import { AssetCustomFieldValue, Clip, CustomFieldTypeName } from '@air/api/types';
import { useCallback } from 'react';

import { PrivateAssetCustomFieldDateInput } from '~/components/RightPanel/CustomFields/PrivateAssetCustomFieldDateInput';
import { PrivateAssetCustomFieldPlainText } from '~/components/RightPanel/CustomFields/PrivateAssetCustomFieldPlainText';
import { PrivateAssetCustomFieldSelect } from '~/components/RightPanel/CustomFields/PrivateAssetCustomFieldSelect';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';
import { canChangeAssetCustomFields } from '~/utils/permissions/assetPermissions';

import { BaseCustomFieldTableCell } from './BaseCustomFieldTableCell';
import { AssetCustomFieldTableCellProps } from './types';

export const PrivateAssetCustomFieldTableCell = ({
  clip,
  customFieldName,
  customFieldId,
}: AssetCustomFieldTableCellProps<Clip>) => {
  const actualCustomFieldValue = clip.customFields?.find((customField) => customField.id === customFieldId);
  const assignedCustomFieldValue = actualCustomFieldValue as AssignedCustomFieldValue | undefined;
  const { customFields } = usePrivateCustomFieldsContext();
  const customField = customFields?.find((field) => field.id === customFieldId);
  const { clipPermissions } = useClipPermissions({
    clipId: clip.id,
  });
  const canEditCustomFieldValues = canChangeAssetCustomFields(clipPermissions);

  const renderEditableCustomField = useCallback(
    (closeEditor: () => void) => {
      if (!customField) return null;
      const assetCustomFieldValue: AssetCustomFieldValue = actualCustomFieldValue || {
        ...customField,
        assetId: clip.assetId,
        value: null,
        values: null,
      };
      switch (customField.type.title) {
        case CustomFieldTypeName.singleSelect:
        case CustomFieldTypeName.multiSelect: {
          return (
            <PrivateAssetCustomFieldSelect
              autoFocus
              assetId={clip.assetId}
              canEditCustomFields={canEditCustomFieldValues}
              onOptionsClose={closeEditor}
              field={assetCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.plainText: {
          return (
            <PrivateAssetCustomFieldPlainText
              isEditing
              assetId={clip.assetId}
              canEditCustomFields={canEditCustomFieldValues}
              field={assetCustomFieldValue}
              onBlur={closeEditor}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.date: {
          return (
            <PrivateAssetCustomFieldDateInput
              isEditing
              assetId={clip.assetId}
              canEditCustomFields={canEditCustomFieldValues}
              field={assetCustomFieldValue}
              onBlur={closeEditor}
              trackingLocation="table-cell"
            />
          );
        }
      }
    },
    [actualCustomFieldValue, clip.assetId, canEditCustomFieldValues, customField],
  );

  return (
    <BaseCustomFieldTableCell
      customFieldName={customFieldName}
      assignedCustomFieldValue={assignedCustomFieldValue}
      renderEditableCustomField={canEditCustomFieldValues ? renderEditableCustomField : undefined}
    />
  );
};
