import { Board, Clip, ClipAndBoardListOptions, ViewTypeName } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GALLERY_MIXED_DATA } from '~/constants/react-query-keys';
import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useRegisterClipEvents } from '~/hooks/useRegisterClipEvents';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { uploadsForCurrentBoardSelector } from '~/store/centralizedBoard/selectors';
import {
  currentSortFieldDirectionSelector,
  currentSortFieldNameSelector,
  currentViewTypeNameSelector,
} from '~/store/configViews/selectors';
import { basePathNameSelector } from '~/store/router/selectors';
import { usePrivateTableViewBoardsDataFetcher } from '~/swr-hooks/gallery/tableView/usePrivateTableViewBoardsDataFetcher';
import { useTableViewItems } from '~/swr-hooks/gallery/tableView/useTableViewItems';
import {
  privateTableViewClipsDataFetcher,
  privateTableViewDataFetcher,
  tableViewDataToUpdateMediaPlayer,
} from '~/swr-hooks/gallery/tableView/utils';
import { itemIsAsset, UsePrivateGalleryMixedData } from '~/swr-hooks/gallery/types';
import { useCanShowBoards } from '~/swr-hooks/gallery/useCanShow';
import { usePrivateMediaPlayerDataUpdate } from '~/swr-hooks/mediaPlayer/usePrivateMediaPlayerData';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';
import { getTypesToFetchFromParams } from '~/swr-hooks/search/utils';

export function usePrivateTableViewItems({ showBoards }: { showBoards: boolean }): UsePrivateGalleryMixedData {
  const { privateTableViewBoardsDataFetcher } = usePrivateTableViewBoardsDataFetcher();
  const { updatePrivateMediaPlayerData } = usePrivateMediaPlayerDataUpdate();
  const { typesParam } = useFilterParams();
  const clipsFetchParams = useClipSearchParams();
  clipsFetchParams.types = getTypesToFetchFromParams(typesParam);
  const boardsFetchParams = useBoardSearchParams();

  const currentSortFieldName = useSelector(currentSortFieldNameSelector);
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const currentSortFieldDirection = useSelector(currentSortFieldDirectionSelector);
  const basePathName = useSelector(basePathNameSelector);
  const uploads = useSelector(uploadsForCurrentBoardSelector);
  const { currentWorkspace } = useCurrentWorkspace();
  const currentWorkspaceId = currentWorkspace?.id;

  const fetchParams: Partial<ClipAndBoardListOptions> = {
    ...clipsFetchParams,
    ...boardsFetchParams,
    sortBy: undefined, // ensure we use the new sort fieldName and direction
  };

  const { canShowBoards: _canShowBoards } = useCanShowBoards();
  const canShowBoards = showBoards && _canShowBoards;

  const dataCacheKey = {
    ...fetchParams,
    ...typesParam,
    canShowBoards,
    currentSortFieldDirection,
    currentSortFieldName,
    basePathName,
    currentWorkspaceId,
  };

  const fetcherParams = {
    params: fetchParams,
    sortFieldName: currentSortFieldName,
    sortDirection: currentSortFieldDirection,
  };

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [GALLERY_MIXED_DATA, dataCacheKey],
    queryFn: ({ pageParam }) => {
      if (!currentWorkspaceId) {
        throw new Error('currentWorkspaceId is required');
      }

      return canShowBoards
        ? typesParam.length === 1 && typesParam[0] === 'boards'
          ? privateTableViewBoardsDataFetcher({
              ...fetcherParams,
              params: boardsFetchParams,
              cursor: pageParam || null,
            })
          : privateTableViewDataFetcher({
              ...fetcherParams,
              cursor: pageParam || null,
              workspaceId: currentWorkspaceId,
            })
        : privateTableViewClipsDataFetcher({
            ...fetcherParams,
            cursor: pageParam || null,
            workspaceId: currentWorkspaceId,
          });
    },
    initialPageParam: '',
    enabled: currentViewTypeName === ViewTypeName.table && !!currentWorkspaceId,
    getNextPageParam: (prevPage) => prevPage.pagination.cursor,
  });

  const itemsData = useTableViewItems<Board, Clip>({
    data: data?.pages ?? [],
    isLoading,
    hasMore: !!hasNextPage,
    isLoadingMore: isFetchingNextPage,
    loadMore: fetchNextPage,
  });

  useEffect(() => {
    updatePrivateMediaPlayerData(tableViewDataToUpdateMediaPlayer(itemsData));
  }, [itemsData, updatePrivateMediaPlayerData]);

  const clips = useMemo(() => itemsData.data.filter(itemIsAsset).map((item) => item.item), [itemsData.data]);

  useRegisterClipEvents({
    clips,
    workspaceId: currentWorkspace?.id,
  });

  return useMemo(
    () => ({
      ...itemsData,
      uploads,
      isEmpty: itemsData.isEmpty && uploads.length === 0,
    }),
    [itemsData, uploads],
  );
}
