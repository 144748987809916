import { Board } from '@air/api/types';
import { memo } from 'react';

import { useSetPrivateFiltersFromContextToRedux } from '~/hooks/filters/useSetPrivateFiltersFromContextToRedux';

export interface PrivateFiltersReduxObserverProps {
  boardId?: Board['id'];
}

export const PrivateFiltersReduxObserver = memo(({ boardId }: PrivateFiltersReduxObserverProps) => {
  useSetPrivateFiltersFromContextToRedux(boardId);

  return null;
});

PrivateFiltersReduxObserver.displayName = 'PrivateFiltersReduxObserver';
