import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { TagsFilter, TagsFilterProps } from '~/components/Filters/TagsFilter/TagsFilter';
import { TagBase } from '~/components/TagsSelect/utils';
import {
  clearTagsFilterValuesAction,
  removeFilterAction,
  setTagsFilterLogicAction,
  setTagsFiltersValueAction,
} from '~/store/filters/actions';
import { selectedTagsFilterLogicSelector, selectedTagsFiltersSelector } from '~/store/filters/selectors';
import { FilterLogicType, SelectedFilter } from '~/store/filters/types';

export interface TagsFilterCardProps extends BaseFilterCardProps {
  TagsSelect: TagsFilterProps['TagsSelect'];
}

export const TagsFilterCard = memo(({ TagsSelect, ...props }: TagsFilterCardProps) => {
  const dispatch = useDispatch();

  const clearValues = useCallback(() => {
    dispatch(clearTagsFilterValuesAction());
  }, [dispatch]);

  const selectedLogic = useSelector(selectedTagsFilterLogicSelector);
  const selectedTags = useSelector(selectedTagsFiltersSelector);

  const onLogicChange = useCallback(
    (logic: FilterLogicType) => dispatch(setTagsFilterLogicAction({ logic })),
    [dispatch],
  );

  const onSelectedTagsChanged = useCallback(
    (tags: TagBase[]) => dispatch(setTagsFiltersValueAction({ tags })),
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="tags" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <TagsFilter
        TagsSelect={TagsSelect}
        selectedLogic={selectedLogic}
        selectedTags={selectedTags}
        onLogicChange={onLogicChange}
        onSelectedTagsChanged={onSelectedTagsChanged}
      />
    </FilterCard>
  );
});

TagsFilterCard.displayName = 'TagsFilterCard';
