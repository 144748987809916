import React, { memo, PropsWithChildren, useMemo } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import {
  AssetDragItem,
  DNDMovableToBoardItem,
} from '~/components/Shared/Drag/DNDMovableToBoardItem/DNDMovableToBoardItem';
import { DNDParentAsset } from '~/components/Shared/Drag/DNDParentAsset';
import DragType from '~/components/Shared/Drag/dragTypes';
import { DropHighlight } from '~/components/Shared/DropHighlight';
import { PrivateAssetTableRowProps } from '~/components/TableView/PrivateTableView/PrivateAssetTableRow';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';

interface PrivateDraggableAssetTableRowProps {
  asset: PrivateAssetTableRowProps['data'];
}

export const PrivateDraggableAssetTableRow = memo(
  ({ children, asset }: PropsWithChildren<PrivateDraggableAssetTableRowProps>) => {
    const itemType = getAssetGalleryItemType(asset);

    const draggableItem: AssetDragItem = useMemo(
      () => ({
        asset,
        assetId: asset.assetId,
        type: itemType === GalleryItemType.asset ? DragType.asset : DragType.file,
      }),
      [asset, itemType],
    );

    return (
      <DNDParentAsset dropLocation="asset" asset={asset}>
        <DropHighlight style={{ right: -16, bottom: -2, zIndex: -1 }} />
        <DNDMovableToBoardItem id={asset.id} item={draggableItem} enabled={true}>
          {children}
        </DNDMovableToBoardItem>
      </DNDParentAsset>
    );
  },
);

PrivateDraggableAssetTableRow.displayName = 'PrivateDraggableAssetTableRow';
