import { Clip } from '@air/api/types';
import { ReactNode } from 'react';

import {
  DNDMovableToBoardItem,
  DNDMovableToBoardItemProps,
} from '~/components/Shared/Drag/DNDMovableToBoardItem/DNDMovableToBoardItem';
import dragTypes from '~/components/Shared/Drag/dragTypes';

export interface DragDroppableFileShellProps extends Pick<DNDMovableToBoardItemProps, 'onDragStart' | 'enabled'> {
  file: Clip;
  children?: ReactNode;
  index?: number;
  onDragStart?: () => void;
}

/** Wrapper around a file-related component that enables drag and drop functionality for the target file */
export const DragDroppableFileShell = ({
  /** File object */
  file,
  file: { id, assetId },
  enabled = true,
  children,
  onDragStart,
  index,
  ...rest
}: DragDroppableFileShellProps) => {
  return (
    <DNDMovableToBoardItem
      id={id}
      item={{
        type: dragTypes.file,
        asset: file,
        assetId,
      }}
      enabled={enabled}
      onDragStart={onDragStart}
      index={index}
      {...rest}
    >
      <>{children}</>
    </DNDMovableToBoardItem>
  );
};
