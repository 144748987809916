import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import {
  WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED,
  WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN,
  WKSPC_MOBILE_HORIZONTAL_PADDING,
} from '~/constants/WorkspaceSpacing';
import { useSideNavContext } from '~/providers/SideNavProvider';

/**
 * responsiveHorizontalPadding: adjusts for mobile/desktop and sidenav open/closed for padding used in many places.
 */
export function usePrivateWorkspaceHorizontalPadding() {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { isSideNavOpen } = useSideNavContext();

  const desktopHorizontalPadding = isSideNavOpen
    ? WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN
    : WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED;

  const responsiveHorizontalPadding = !isAboveMediumScreen ? WKSPC_MOBILE_HORIZONTAL_PADDING : desktopHorizontalPadding;

  const responsiveHorizontalPaddingArray = useMemo(
    () => [WKSPC_MOBILE_HORIZONTAL_PADDING, WKSPC_MOBILE_HORIZONTAL_PADDING, desktopHorizontalPadding],
    [desktopHorizontalPadding],
  );

  return useMemo(
    () => ({
      responsiveHorizontalPadding,
      responsiveHorizontalPaddingArray,
    }),
    [responsiveHorizontalPadding, responsiveHorizontalPaddingArray],
  );
}
