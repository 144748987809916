import { memo } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { PrivateTagsSelect } from '~/components/TagsSelect/PrivateTagsSelect';

import { TagsFilterCard } from './TagsFilterCard';

export const PrivateTagsFiltersCard = memo((props: BaseFilterCardProps) => (
  <TagsFilterCard TagsSelect={PrivateTagsSelect} {...props} />
));

PrivateTagsFiltersCard.displayName = 'PrivateTagsFiltersCard';
