import { Board } from '@air/api/types';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateCopyrightsList } from '~/swr-hooks/filters/usePrivateCopyrightList';

interface PrivateCopyrightSelectProps extends BaseStringSelectProps {
  boardId: Board['id'] | undefined;
  libraryId: string | undefined;
}

export const PrivateCopyrightSelect = memo(({ boardId, libraryId, ...props }: PrivateCopyrightSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePrivateCopyrightsList({
    search: debouncedSearchValue,
    boardId,
    libraryId,
  });

  return (
    <StringSelect
      placeholder="Search for copyright"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PrivateCopyrightSelect.displayName = 'PrivateCopyrightSelect';
