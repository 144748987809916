import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePrivateCitiesList } from '~/swr-hooks/filters/usePrivateCitiesList';

interface PrivateCitiesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCitiesSelect = memo(({ boardId, libraryId, ...props }: PrivateCitiesSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePrivateCitiesList({
    search: debouncedSearchValue,
    boardId,
    libraryId,
  });

  return (
    <StringSelect
      placeholder="Search for city"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PrivateCitiesSelect.displayName = 'PrivateCitiesSelect';
