import { Box } from '@air/zephyr';
import { ComponentType, memo } from 'react';

import { FilterLogicSelect } from '~/components/Filters/FilterLogicSelect';
import { PrivateTagsSelectProps } from '~/components/TagsSelect/PrivateTagsSelect';
import { TagBase } from '~/components/TagsSelect/utils';
import { FilterLogicType } from '~/store/filters/types';

export interface TagsFilterProps {
  TagsSelect: ComponentType<PrivateTagsSelectProps>;
  selectedLogic: any;
  selectedTags: TagBase[];
  onLogicChange: (logic: FilterLogicType) => void;
  onSelectedTagsChanged: (tags: TagBase[]) => void;
}

export const TagsFilter = memo(
  ({ TagsSelect, onLogicChange, onSelectedTagsChanged, selectedTags, selectedLogic }: TagsFilterProps) => {
    return (
      <Box>
        <FilterLogicSelect selectedLogic={selectedLogic} onLogicChange={onLogicChange} />
        <TagsSelect
          hasDropdownOverlay
          variant="field-input-smol"
          isClearable={false}
          alwaysShowClearItemButton
          selectedTags={selectedTags}
          onSelectedTagsChanged={onSelectedTagsChanged}
        />
      </Box>
    );
  },
);

TagsFilter.displayName = 'TagsFilter';
