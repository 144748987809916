import { Chip } from '@air/primitive-chip';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { SelectChipOptionContainer } from '~/components/Zephyr/Select/components/SelectChipOptionContainer';
import { SelectChipRemoveButton } from '~/components/Zephyr/Select/components/SelectChipRemoveButton';
import { Select, SelectProps } from '~/components/Zephyr/Select/Select';
import { useImportedKeywordsList } from '~/swr-hooks/useImportedKeywordsList';

export interface ImportedKeywordsSelectProps
  extends Omit<SelectProps, 'options' | 'selectedOptions' | 'onSelectionChange' | 'placeholder'> {
  selectedKeywords: string[];
  onSelectedKeywordsChange: (newKeywords: string[]) => void;
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const ImportedKeywordsSelect = memo(
  ({ onSelectedKeywordsChange, selectedKeywords, boardId, libraryId, ...props }: ImportedKeywordsSelectProps) => {
    const [searchValue, setSearchValue] = useState('');

    const [debouncedSearchValue] = useDebounce(searchValue, 200, {
      trailing: true,
    });

    const { data: keywords = [], isLoading } = useImportedKeywordsList({
      search: debouncedSearchValue,
      boardId,
      libraryId,
    });

    const onSelectionChange = useCallback<SelectProps['onSelectionChange']>(
      ({ chips }) => {
        const keywords = chips.map(({ value }) => value);
        onSelectedKeywordsChange(keywords);
      },
      [onSelectedKeywordsChange],
    );

    const listItemRenderer = useCallback<Required<SelectProps>['listItemRenderer']>(
      (keyword) => (
        <SelectChipOptionContainer className="-mx-0.5 py-2">
          <Chip appearance="rounded" size="medium" className="bg-grey-5 px-2 text-grey-11" key={keyword.value}>
            {keyword.label}
          </Chip>
        </SelectChipOptionContainer>
      ),
      [],
    );

    const selectedItemRenderer = useCallback<Required<SelectProps>['chipRenderer']>(
      (keyword) => (
        <Chip
          appearance="rounded"
          size="medium"
          className="mr-1.5 bg-grey-5 px-2 text-grey-11"
          key={keyword.value}
          suffix={
            <SelectChipRemoveButton
              chip={keyword}
              onClick={() => {
                onSelectedKeywordsChange(selectedKeywords.filter((k) => k !== keyword.value));
              }}
            />
          }
        >
          {keyword.label}
        </Chip>
      ),
      [onSelectedKeywordsChange, selectedKeywords],
    );

    const options = useMemo(() => keywords.map((value) => ({ value, label: value })), [keywords]);

    return (
      <Select
        options={options}
        maxDropdownHeight={280}
        isLoading={isLoading}
        selectedOptions={selectedKeywords.map((keyword) => ({ value: keyword, label: keyword }))}
        onSelectionChange={onSelectionChange}
        onInputChange={setSearchValue}
        renderAsInput={true}
        isClearable={true}
        isSearchable={true}
        listItemRenderer={listItemRenderer}
        chipRenderer={selectedItemRenderer}
        hasDropdownOverlay
        isSticky
        placeholder="Search for keywords"
        variant="field-input-smol"
        {...props}
      />
    );
  },
);

ImportedKeywordsSelect.displayName = 'ImportedKeywordsSelect';
