import { AirActionTrackingLocation } from '@air/analytics';
import { AssetCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldDateInput,
  CustomFieldDateInputProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldDateInput';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateAssetCustomFieldDateInputProps extends Omit<CustomFieldDateInputProps, 'onChange'> {
  field: AssetCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  assetId: string;
}

export const PrivateAssetCustomFieldDateInput = memo(
  ({ field, trackingLocation, canEditCustomFields, assetId, ...rest }: PrivateAssetCustomFieldDateInputProps) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;

    const onChange: CustomFieldDateInputProps['onChange'] = useCallback(
      async (date) => {
        if (!workspaceId) {
          return;
        }

        await updateItemsCustomFieldValue({
          assetIds: [assetId],
          baseCustomField: field,
          nextDateValue: date?.toISOString(),
          trackingLocation,
          workspaceId,
        });
      },
      [assetId, field, trackingLocation, updateItemsCustomFieldValue, workspaceId],
    );

    return (
      <CustomFieldDateInput {...rest} field={field} onChange={onChange} canEditCustomFields={canEditCustomFields} />
    );
  },
);

PrivateAssetCustomFieldDateInput.displayName = 'PrivateAssetCustomFieldDateInput';
