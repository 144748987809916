import { useTrackClickedSaveCustomField } from '@air/analytics';
import { CustomFields } from '@air/api';
import { EditableText } from '@air/zephyr-editable-text';
import { useQueryClient } from '@tanstack/react-query';
import { memo, useState } from 'react';

import { TableCell } from '~/components/TableView/ui';
import { getPrivateBoardCustomFieldsKey } from '~/constants/react-query-keys';
import { TABLE_CUSTOM_FIELD_NAME_EDITABLE_TEXT } from '~/constants/testIDs';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetPrivateConfigViewOptions } from '~/swr-hooks/gallery/viewFields/useGetPrivateConfigViewsOptions';
import { canEditCustomFields } from '~/utils/permissions/workspacePermissions';

import { getTableColumnWidth } from '../types';
import { CustomFieldTableHeaderProps } from './types';

const EDITABLE_TEXT_PADDING = 6;
const EDITABLE_TEXT_HEIGHT = 18;

export const PrivateCustomFieldTableHeader = memo(({ customFieldId, customFieldName }: CustomFieldTableHeaderProps) => {
  const boardId = useURLBoardIdSelector();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const [forceEditableTextMount, setForceEditableTextMount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const { refetch: refetchConfigViews } = useGetPrivateConfigViewOptions();
  const queryClient = useQueryClient();
  const { trackClickedSaveCustomField } = useTrackClickedSaveCustomField();

  const onSubmit = async (values: { ['editable-text-value']: string }) => {
    if (!workspaceId) {
      throw new Error('Workspace ID is not defined');
    }

    const name = values['editable-text-value'];
    if (!name) {
      setForceEditableTextMount((v) => v + 1);
      return;
    }

    await CustomFields.updateCustomField({ workspaceId, customField: { name, id: customFieldId } });

    refetchConfigViews();

    // In the scenario in which the boards custom fields panel is open
    queryClient.invalidateQueries({ queryKey: getPrivateBoardCustomFieldsKey(boardId) });

    trackClickedSaveCustomField({ location: 'table-header' });
  };

  return (
    <TableCell width={getTableColumnWidth('customField')} data-testid={customFieldName} data-disableselect="true">
      {canEditCustomFields(workspacePermissions) ? (
        <EditableText
          key={forceEditableTextMount}
          // TODO: `minLength` did not work as expected, this is a quick hack
          // to force a remount which causes EditableText to show the initial
          // state before it was opened.
          maxLength={40}
          isSingleLine
          isEditing={isEditing}
          onEditingStateChange={setIsEditing}
          tx={{
            mx: -EDITABLE_TEXT_PADDING * 2,
            width: '100%',
            EditableTextText: {
              fontSize: 12,
              color: 'var(--colors-grey10)',
              fontWeight: 'semibold',
              minHeight: EDITABLE_TEXT_HEIGHT,
            },
            EditableTextButton: {
              wordBreak: 'break-word',
              whiteSpace: isEditing ? 'normal' : 'nowrap',
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '&.focus-visible': {
                boxShadow: 'none !important',
                backgroundColor: 'transparent',
              },
            },
          }}
          label="Custom field name"
          placeholder="Custom field name"
          value={customFieldName}
          onSubmit={onSubmit}
          data-testid={`${TABLE_CUSTOM_FIELD_NAME_EDITABLE_TEXT}-${customFieldName}`}
        />
      ) : (
        customFieldName
      )}
    </TableCell>
  );
});
PrivateCustomFieldTableHeader.displayName = 'PrivateCustomFieldTableHeader';
