import { type BaseCustomField } from '@air/api/types';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { ViewCardSizePreference } from '~/components/ViewControls/ViewVisiblityToggleMenu/ViewCardSizePreference';
import { ViewVisibilityThumbnailPreference } from '~/components/ViewControls/ViewVisiblityToggleMenu/ViewVisibilityThumbnailPreference';
import { useGetAvailableCustomFields } from '~/hooks/useGetAvailableCustomFields';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import {
  configViewVisiblityOptionsSelector,
  currentViewTypeNameSelector,
  customFieldVisibilityOptionsSelector,
} from '~/store/configViews/selectors';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

import { ConfigViewVisbilityOptions } from './ConfigViewVisbilityOptions';
import { CustomFieldsVisbilityOptions } from './CustomFieldsVisbilityOptions';
import { ViewVisibilityToggleMenu } from './ViewVisibilityToggleMenu';

export const PrivateViewVisibilityToggleMenu = memo(() => {
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const canViewCustomFields = canSeeCustomFields(workspacePermissions);
  const customFieldOptions = useSelector(customFieldVisibilityOptionsSelector);
  const configViewOptions = useSelector(configViewVisiblityOptionsSelector);
  const urlLibraryId = useURLLibraryIdSelector();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const showCustomFieldsOptions = customFieldOptions.length > 0 && canViewCustomFields;
  const showConfigViewOptions = configViewOptions.length > 0;
  const { customFields } = usePrivateCustomFieldsContext();
  const { data: libraryCustomFieldsVisbility } = useLibraryCustomFields({ libraryId: libraryId || urlLibraryId });
  const { getAvailableCustomFields } = useGetAvailableCustomFields<BaseCustomField>({ libraryId });

  const filteredCustomFields = useMemo(() => {
    return getAvailableCustomFields(customFields);
  }, [customFields, getAvailableCustomFields]);

  const filteredCustomFieldOptions = useMemo(() => {
    return !!libraryCustomFieldsVisbility?.data.length
      ? customFieldOptions.filter((customField) => {
          return !!libraryCustomFieldsVisbility.data.find((field) => field.id === customField.customFieldId)?.visible;
        })
      : customFieldOptions;
  }, [customFieldOptions, libraryCustomFieldsVisbility?.data]);

  return (
    <ViewVisibilityToggleMenu>
      <>
        {currentViewTypeName === 'gallery' && (
          <>
            <ViewCardSizePreference />
            <ViewVisibilityThumbnailPreference />
            <div className="-mx-1.5 my-2 h-px shrink-0 bg-grey-4" />
          </>
        )}
        {showConfigViewOptions && <ConfigViewVisbilityOptions options={configViewOptions} />}
        {showConfigViewOptions && showCustomFieldsOptions && <div className="-mx-1.5 my-2 h-px bg-grey-5" />}
        {showCustomFieldsOptions && !!filteredCustomFields?.length && (
          <CustomFieldsVisbilityOptions customFields={filteredCustomFields} options={filteredCustomFieldOptions} />
        )}
      </>
    </ViewVisibilityToggleMenu>
  );
});

PrivateViewVisibilityToggleMenu.displayName = 'PrivateViewVisibilityToggleMenu';
