import { formatFullName } from '@air/utilities';

import { Uploader } from '~/components/Filters/UploaderFilter/UploaderFilterCard';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';

export interface UploaderOption extends Uploader, DefaultChipType {}

export const convertUploaderToOption = (uploader: Uploader): UploaderOption => ({
  ...uploader,
  value: uploader.id,
  label: formatFullName(uploader.firstName, uploader.lastName) ?? '',
});
