import { memo, useCallback } from 'react';

import {
  CamerasFilterCard,
  CamerasFilterCardProps,
} from '~/components/Filters/CamerasFilter/components/CamerasFilterCard';
import { PrivateCameraMakeSelect } from '~/components/Filters/CamerasFilter/components/PrivateCameraMakeSelect';
import { PrivateCameraModelsSelect } from '~/components/Filters/CamerasFilter/components/PrivateCameraModelsSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';

interface PrivateCamerasFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
  libraryId: string | undefined;
}

export const PrivateCamerasFilterCard = memo(({ boardId, libraryId, ...props }: PrivateCamerasFilterCardProps) => {
  const renderModelsSelect = useCallback<CamerasFilterCardProps['CameraModelsSelect']>(
    (props) => <PrivateCameraModelsSelect libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  const renderMakeSelect = useCallback<CamerasFilterCardProps['CameraMakeSelect']>(
    (props) => <PrivateCameraMakeSelect libraryId={libraryId} boardId={boardId} {...props} />,
    [boardId, libraryId],
  );

  return <CamerasFilterCard CameraModelsSelect={renderModelsSelect} CameraMakeSelect={renderMakeSelect} {...props} />;
});

PrivateCamerasFilterCard.displayName = 'PrivateCamerasFilterCard';
