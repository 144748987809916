import { formatFullName, formatInitials } from '@air/utilities';
import { Box, TXProp } from '@air/zephyr';
import { rgba } from 'polished';
import { useTheme } from 'styled-components';

import { Avatar } from '~/components/Avatar';
import { UploaderOption } from '~/components/Selects/Uploader/utils';
import { SelectChipRemoveButton } from '~/components/Zephyr/Select/components/SelectChipRemoveButton';

export interface UploaderChipProps {
  uploader: UploaderOption;
  tx?: TXProp;
  onRemoveUploader?: (uploader: UploaderOption) => void;
}

export const UploaderChip = ({
  uploader,
  uploader: { id, avatar, firstName, lastName },
  tx,
  onRemoveUploader,
}: UploaderChipProps) => {
  const theme = useTheme();

  return (
    <Box
      tx={{
        backgroundColor: rgba(theme.colors.pigeon800, 0.05),
        borderRadius: 24,
        p: 2,
        pr: 6,
        display: 'flex',
        alignItems: 'center',
        ...tx,
      }}
    >
      <Avatar appearance="circle" size={20} src={avatar} text={formatInitials(firstName, lastName)} colorSeed={id} />
      <div className="ml-1 mr-0.5 truncate text-12 font-medium text-grey-12">{formatFullName(firstName, lastName)}</div>
      {onRemoveUploader && <SelectChipRemoveButton chip={uploader} onClick={onRemoveUploader} />}
    </Box>
  );
};
