import { Board, Clip } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { Upload } from '@air/redux-uploader';
import { constant } from 'lodash';
import React, { useMemo } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import { UploadTableRow } from '~/components/TableView/PrivateTableView/UploadTableRow';
import { getLoaderRow, MOBILE_TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT } from '~/components/TableView/tableRowUtils';
import { AirTableRow } from '~/components/TableView/types';
import { usePrivateWorkspaceHorizontalPadding } from '~/hooks/usePrivateWorkspaceHorizontalPadding';
import { useBoardsBeingDeleted } from '~/swr-hooks/boards/useBoardsBeingDeleted';
import { UseGalleryMixedData } from '~/swr-hooks/gallery/types';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';

export interface UsePrivateGetTableRowsParams
  extends Pick<UseGalleryMixedData<Board, Clip>, 'data' | 'hasMore' | 'isLoading' | 'isLoadingMore'> {
  loadMore: () => void;
  uploads: Upload[];
  renderBoardRow?: (params: { rowStyle: React.CSSProperties; key: string | number; data: Board }) => React.ReactNode;
  isBoardSelectable?: (boardId: string) => boolean;
  renderAssetRow?: (params: { rowStyle: React.CSSProperties; key: string | number; data: Clip }) => React.ReactNode;
  isAssetSelectable?: (clipId: string) => boolean;
}

export const usePrivateTableRows = ({
  data,
  hasMore,
  isLoading,
  isLoadingMore,
  loadMore,
  uploads,
  renderAssetRow,
  isAssetSelectable = constant(false),
  renderBoardRow,
  isBoardSelectable = constant(false),
}: UsePrivateGetTableRowsParams) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { responsiveHorizontalPaddingArray } = usePrivateWorkspaceHorizontalPadding();
  const boardsBeingDeleted = useBoardsBeingDeleted();

  const rows = useMemo(() => {
    const tableRows: AirTableRow[] = [];

    uploads.forEach((upload) => {
      tableRows.push({
        height: !isAboveMediumScreen ? MOBILE_TABLE_ROW_HEIGHT : TABLE_ROW_HEIGHT,
        getMetadata: () => ({
          isSelectable: false,
          type: GalleryItemType.upload,
          item: upload,
        }),
        // eslint-disable-next-line react/display-name
        render: (rowStyle, key) => (
          <UploadTableRow
            key={key}
            upload={upload}
            style={rowStyle}
            tx={{
              px: responsiveHorizontalPaddingArray,
            }}
          />
        ),
      });
    });

    data.forEach((rowData) => {
      switch (rowData.type) {
        case 'board':
          {
            if (!boardsBeingDeleted.includes(rowData.item.id)) {
              tableRows.push({
                height: !isAboveMediumScreen ? MOBILE_TABLE_ROW_HEIGHT : TABLE_ROW_HEIGHT,
                getMetadata: () => ({
                  isSelectable: isBoardSelectable(rowData.item.id),
                  item: rowData.item,
                  type: GalleryItemType.board,
                }),
                render: (rowStyle, key) => renderBoardRow?.({ rowStyle, key, data: rowData.item }),
              });
            }
          }
          break;

        case 'asset':
        case 'file':
          tableRows.push({
            height: !isAboveMediumScreen ? MOBILE_TABLE_ROW_HEIGHT : TABLE_ROW_HEIGHT,
            getMetadata: () => ({
              isSelectable: isAssetSelectable(rowData.item.id),
              item: rowData.item,
              type: getAssetGalleryItemType(rowData.item),
            }),
            render: (rowStyle, key) => renderAssetRow?.({ rowStyle, key, data: rowData.item }),
          });
          break;
      }
    });

    if (hasMore || isLoading || isLoadingMore) {
      tableRows.push(getLoaderRow(isLoading, loadMore));
    }

    return tableRows;
  }, [
    uploads,
    data,
    hasMore,
    isLoading,
    isLoadingMore,
    isAboveMediumScreen,
    responsiveHorizontalPaddingArray,
    boardsBeingDeleted,
    isBoardSelectable,
    renderBoardRow,
    isAssetSelectable,
    renderAssetRow,
    loadMore,
  ]);

  return rows;
};
